import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import TncBack from "../Cards/TncBack";
import "./style.scss";
import CardListModal from "../Cards/CardListModal";
interface Props {
  handleModal: Function;
  xc_data: any;
}

const OnlyXoxoCode = (props: any) => {
  const [showTnc, setShowTnc] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleModal,
    xc_data,
    redemption_check,
    removeSelected,
    handleHtml,
    campaign,
    xc_selected,
    isUserVerified,
    customStyles,
    delivery_method,
    otpId,
    setOtpId,
    linkCode,
    handleXCSelected,
    showCts,
    authenticate_email,
    maskedEmail,
    authenticate_phone,
    maskedPhone,
    isGvLink,
  } = props;

  const toggleTnc = () => {
    setShowTnc((prevState) => {
      return !prevState;
    });
  };

  return showTnc ? (
    <TncBack
      showTnc={showTnc}
      toggleTnc={toggleTnc}
      data={xc_data}
      overlay={false}
    />
  ) : !xc_selected ? (
    <>
      <div className="onlyxoxocode">
        <div className="heading">
          Explore Xoxoday’s Global Voucher and redeem from over{" "}
          <div className="highlight">2000+</div> brands across{" "}
          <div className="highlight">70+</div> countries.
        </div>
        <Row xs="1" lg="3" className="features">
          <Col className="feature">
            <div className="title">
              <div className="icon">
                <img src="/img/icons/globe.svg" alt="" />
              </div>
              <div className="text">Global Vouchers</div>
            </div>
            <div className="subtitle">
              Delight employees with an extensive global catalog of{" "}
              <div className="bold">2,000+ reward</div> choices
            </div>
          </Col>
          <Col className="feature">
            <div className="title">
              <div className="icon">
                <img src="/img/icons/grid.svg" alt="" />
              </div>
              <div className="text">Multiple Currencies</div>
            </div>
            <div className="subtitle">
              Redeem the same reward value converted in the{" "}
              <div className="bold">currency of your choice</div>.
            </div>
          </Col>{" "}
          <Col className="feature">
            <div className="title">
              <div className="icon">
                <img src="/img/icons/redeem-icon.svg" alt="" />
              </div>
              <div className="text">Redeem</div>
            </div>
            <div className="subtitle">
              Receive the redeemed product via email or phone.
            </div>
          </Col>
        </Row>
        <div className="buttons">
          <Button
            outline
            className="how-to-use"
            color="default"
            onClick={toggleTnc}
          >
            How to use
          </Button>
          <Button
            className="collect-voucher"
            color="default"
            onClick={() => {
              if (redemption_check) {
                handleXCSelected();
              } else {
                handleModal(0, true, "", "", {}, 0, true);
              }
            }}
            disabled={loading}
          >
            {loading ? "Collecting..." : "Collect voucher"}
          </Button>
        </div>
      </div>
    </>
  ) : (
    <CardListModal
      item={xc_data}
      selected={xc_selected}
      removeSelected={removeSelected}
      data={xc_data}
      handleHtml={handleHtml}
      campaign={campaign}
      isUserVerified={isUserVerified}
      redemption_check={redemption_check}
      customStyles={customStyles}
      xc_selected={xc_selected}
      xc_data={xc_data}
      code={xc_data.code}
      otpId={otpId}
      setOtpId={setOtpId}
      handleModal={handleModal}
      delivery_method={delivery_method}
      linkCode={linkCode}
      onlyXoxoCode={true}
      showCts={showCts}
      authenticate_email={authenticate_email}
      maskedEmail={maskedEmail}
      authenticate_phone={authenticate_phone}
      maskedPhone={maskedPhone}
      isGvLink={isGvLink}
    />
  );
};

export default OnlyXoxoCode;
