import React, { Suspense, lazy } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import { ROUTE_PATHS } from "./commons/constants/routes.constants";

import Home from "./pages/home/index";
import ClaimReward from "./pages/claimReward/index";
import PageLoader from "./commons/components/Loader/PageLoader";
import { getUrlQueryParams, isLengthyArray, setCookie } from "./utils/helpers";

const PageNotFound = lazy(() => import("./pages/pageNotFound/PageNotFound"));

interface RoutesProps {
  // branding: any;
  // userCountry: number;
  // isLoggedIn: boolean;
  // appInfo : any;
}
interface State {
  custom_url: String;
  custom_route: String;
}
class Routes extends React.Component<RoutesProps, State> {
  state = {
    custom_url: "",
    custom_route: "",
  };

  componentWillMount() {
    if (window.location.protocol !== "https:" && window.location.hostname !== "localhost") {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
    if (window.location.pathname == "/sso/") {
      const parsedUrlQuery = getUrlQueryParams();
      if (parsedUrlQuery && parsedUrlQuery.token) {
        setCookie("s_e_t=1; a_t=" + parsedUrlQuery.token);
      }
    }
  }

  render() {
    // console.log("version 2.0")

    return (
      <Suspense fallback={<PageLoader />}>
        <BrowserRouter basename={"/"}>
          <Switch>
            <Route
              path={ROUTE_PATHS.CLAIMREWARD + "/:app_code/:automation_id/:rid"}
              exact={true}
              component={ClaimReward}
            />
            <Route
              path={ROUTE_PATHS.HOME + ":keyword"}
              exact={true}
              component={Home}
            />

            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}

export default Routes;
