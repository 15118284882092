import React from "react";

interface Props {
  description: string;
  customClass: string;
  descriptionColour: string;
  descriptionStatus: number;
}

const DescriptionText: React.FC<Props> = ({
  description,
  customClass,
  descriptionColour,
  descriptionStatus,
}) => {
  return (
    <>
      {descriptionStatus ? (
        <div
          className={`mt-3 landingDescription mb-3 text-break ${
            customClass ? customClass : ""
          }`}
          style={{
            color: descriptionColour,
          }}
        >
          {description}
        </div>
      ) : null}
    </>
  );
};

export default DescriptionText;
