import request from "axios";
import { GraphQlPayloadType } from "./api.types";

const baseUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const consumerUrl = process.env.REACT_APP_CONSUMER_URL;
const basePublicUrl = process.env.REACT_APP_PUBLIC_ENDPOINT;
const baseGraphOnly = process.env.REACT_APP_GRAPHONLY_ENDPOINT;
const checkoutUrl = process.env.REACT_APP_CHECKOUT_URL;

class BaseApi {
  /*
   * http external call (GET|POST)
   * @param {object} payload
   * @param string url
   * @param string method
   * @param {object} extraHeaders
   * @param int timeout
   * @returns {Promise}
   */
  // constructor () {

  // }

  makeAuthRequest = async (
    payload: any = {},
    url: string = "",
    method: string = "POST",
    extraHeaders: object = {},
    timeout: number = 60000
  ) => {
    const subEndPoint = payload.query ? payload.query.split(".")[2] : "";
    const headers = { ...this.getHeaders(), ...extraHeaders };
    const endPoint = payload.tag
      ? `${url}/${payload.tag}/${subEndPoint}`
      : `${url}/${subEndPoint}`;
    const requestOptions: any = {
      method: method,
      url: endPoint,
      headers: headers,
      data: payload,
      timeout: timeout,
      json: true,
      withCredentials: true,
    };
    return await request(requestOptions);
  };

  /*
   * http external call (GET|POST)
   * @param {object} payload
   * @param string url
   * @param string method
   * @param {object} extraHeaders
   * @param int timeout
   * @returns {Promise}
   */
  makeRequest = async (
    queryParams: any = {},
    url: string = "",
    method: string = "POST",
    headers: object = {},
    payload: any = "",
    timeout: number = 60000
  ) => {
    const requestOptions: any = {
      method: method,
      url: url,
      headers: headers,
      params: queryParams,
      timeout: timeout,
    };
    if (payload !== "") {
      requestOptions.data = payload;
    }
    return await request(requestOptions);
  };

  /*
   * @description : getHeaders
   * @returns {object}
   */
  getHeaders = () => {
    return {
      "Content-Type": "application/json",
      pltfm: 4,
      lng: "en",
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  };

  /**
   * @description: getGraphqlPayload
   * @returns {object}
   */
  getGraphqlPayload = (
    query: string,
    tag: string,
    variables: any
  ): GraphQlPayloadType => {
    return {
      query,
      tag,
      variables,
    };
  };

  /*
   * @description : makeUrl
   * @param string endPoint
   * @returns string
   */
  makeUrl = (endPoint: string): string => {
    return `${baseUrl}${endPoint}`;
  };

  /*
   * @description : makeBaseUrl
   * @param string endPoint
   * @returns string
   */
  makeBaseUrl = (endPoint: string): string => {
    return `${consumerUrl}${endPoint}`;
  };

  /*
   * @description : makePublicUrl
   * @param string endPoint
   * @returns {string}
   */
  makePublicUrl = (endPoint: string): string => {
    return `${basePublicUrl}${endPoint}`;
  };

  /*
   * @description : makeGraphUrl
   * @param string endPoint
   * @returns {object}
   */
  makeGraphUrl = (endPoint: string = ""): string => {
    return `${baseGraphOnly}${endPoint}`;
  };

  /*
   * @description : makeCheckoutUrl
   * @param string endPoint
   * @returns {object}
   */
  makeCheckoutUrl = (endPoint: string): string => {
    // return `${checkoutUrl}${endPoint}`;
    return `${checkoutUrl}${endPoint}`;
  };
}

export default BaseApi;
