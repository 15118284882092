import axios from "axios";
import BaseApi from "./baseApi.service";
import { API_ROUTES, GRAPHQL_QUERIES } from "./api.constants";
import { makeRequestAuth } from "../utils/globals";
import config from "../../config/config.production.json";

const anonymousUrl = config.giftauth;
const storesUrl = process.env.REACT_APP_STORES_URL;
const node_server = process.env.REACT_APP_NODE_SERVER;

class Api extends BaseApi {
  fetchVouchers = async (linkValue = "") => {
    const query = GRAPHQL_QUERIES.FETCH_VOUCHERS;
    const payload = { code: linkValue };
    const post_data = {
      query: query,
      tag: "stores",
      variables: {
        data: JSON.stringify(payload),
      },
    };

    try {
      const { data } = await makeRequestAuth({
        data: post_data,
        codeLength: linkValue.length ? linkValue.length : 16,
      });

      return JSON.parse(data.fetchVouchers.data || "{}");
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  linkStatus = async (linkValue = "") => {
    const query = GRAPHQL_QUERIES.LINK_STATUS;
    const payload = { code: linkValue };
    const post_data = {
      query: query,
      tag: "stores",
      variables: {
        data: JSON.stringify(payload),
      },
    };

    try {
      const { data } = await makeRequestAuth({
        data: post_data,
        codeLength: linkValue.length ? linkValue.length : 16,
      });
      const parsedData = JSON.parse(data.linkStatus.data || "{}");
      return { ...parsedData, showCts: data.linkStatus.showCts };
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  getCountryIsoCode2 = async () => {
    const url = `${storesUrl}/chef/api/public/graph`;

    const add_data = {
      key: "vd",
      method: "files/ipDetails",
      url_type: "node_partner",
    };

    const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.PLUM_API, "stores", {
      add_data,
    });
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      let output = JSON.parse(
        data.data && data.data.plumApi && data.data.plumApi.output
      );
      return output;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  verifyResponseID = async (variables = {}, url = "") => {
    const urlObj = anonymousUrl + url;
    let payload = JSON.stringify(variables);

    try {
      const { data } = await axios(urlObj, {
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  claimReward = async (variables = {}, url = "") => {
    const urlObj = anonymousUrl + url;
    let payload = JSON.stringify(variables);

    try {
      const { data } = await axios(urlObj, {
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchDataFromPincode = async (postal_code: String, country_code: String) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = `${storesUrl}/chef/api/public/graph`;

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.VALIDATE_POSTAL_CODE,
      "stores",
      { postal_code, country_code }
    );
    try {
      const response = await this.makeAuthRequest(payload, url);

      const data = response.data.data.fetchPostalCodeData;
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  fetchCities = async (country_id: any) => {
    const url = `${storesUrl}/chef/api/public/graph`;
    const add_data = {
      country_id,
      key: "vd",
      method: "getLiveCities",
      url_type: "node_partner",
    };
    const payload = this.getGraphqlPayload(GRAPHQL_QUERIES.PLUM_API, "stores", {
      add_data,
    });
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      const output = JSON.parse(
        data.data && data.data.plumApi && data.data.plumApi.output
      );

      return output;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchStateCities = async (state_id: number) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = `${storesUrl}/chef/api/public/graph`;

    const variables = { state_id };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_CITIES,
      "stores",
      { ...variables }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  fetchStates = async (country_id: number) => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = `${storesUrl}/chef/api/public/graph`;

    const variables = { country_id };
    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_STATES,
      "stores",
      { ...variables }
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  fetchCountries = async () => {
    const endPoint = API_ROUTES.DEFAULT_PUBLIC_ENDPOINT;
    const url = `${storesUrl}/chef/api/public/graph`;

    const payload = this.getGraphqlPayload(
      GRAPHQL_QUERIES.GET_COUNTRY,
      "stores",
      {}
    );
    try {
      const { data } = await this.makeAuthRequest(payload, url);
      return data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  //http://localhost:4003/integration/qualtricsresponse/validateResponse
}

const ApiService = new Api();
Object.freeze(ApiService);

export default ApiService;
