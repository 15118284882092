import React, { useState, useEffect } from "react";
import "./verify-mobile.scss";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import TncBack from "../Cards/TncBack";
import { WarningIcon } from "../../../../commons/components/Icons";

const anchorStyle = {
  color: "#0172CB",
};

const VerifyMobile = ({ product = {}, phone, errCode }: any) => {
  const [showTnc, setShowTnc] = useState(false);
  const [showPopup, setPopup] = useState(false);
  const toggleTnc = () => {
    setShowTnc((showTnc) => !showTnc);
  };

  const pendingText = {
    icon: null,
    title: "🎉 Thank you for placing the order!",
    subtitle: (
      <div>
        The Reward value will be added to the {product.name} wallet on the phone
        number provided. Please refresh the link after sometime to view the
        latest status
      </div>
    ),
  };

  const processedText = {
    icon: null,
    title: "Order processed!",
    subtitle: (
      <div>
        The {product.name} order has been processed and the funds have been
        added to <br /> {phone} For any further queries, <br /> please email{" "}
        <a href="mailto:cs@giift.com" target="_blank">
          cs@giift.com
        </a>
      </div>
    ),
  };
  const lowBalanceText = {
    icon: <WarningIcon />,
    title: "Error : #001 : The link is currently unavailable for redemption!",
    subtitle: (
      <span>
        Please check back in 24-48 hours or write to{" "}
        <a href="mailto:cs@giift.com" target="_blank" style={anchorStyle}>
          cs@giift.com
        </a>
      </span>
    ),
  };

  const text =
    errCode === ""
      ? processedText
      : errCode === "LOW_BALANCE"
      ? lowBalanceText
      : pendingText;

  useEffect(() => {
    setPopup(true);
  }, []);

  return (
    <>
      {/* <meta http-equiv="refresh" content="30" /> */}
      {showTnc ? (
        <TncBack showTnc={showTnc} toggleTnc={toggleTnc} data={product} />
      ) : (
        <Modal
          isOpen={showPopup}
          backdrop={false}
          fade={false}
          className="verify-mobile"
        >
          <ModalBody className="text-center modal-padding">
            <Row>
              <Col className="text-center">
                {text.icon ? (
                  <div className="verify-mobile_subtitle">{text.icon}</div>
                ) : null}
                <div className="verify-mobile_title">{text.title}</div>
                <div className="verify-mobile_subtitle">{text.subtitle}</div>
                {errCode !== "LOW_BALANCE" ? (
                  <div className="verify-mobile_tnc" onClick={toggleTnc}>
                    Terms & Conditions | How to use
                  </div>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default VerifyMobile;
