import React from "react";
import DescriptionText from "../components/DescriptionText";
import HowToRedeemBtn from "../components/HowToRedeemBtn";
import RedeemBtn from "../components/RedeemBtn";
import "./style.scss";

interface LayoutProps {
  landingSettings: any;
  showVouchers: Function;
}

const CasualLayout: React.FC<LayoutProps> = ({
  landingSettings,
  showVouchers,
}) => {
  const isMobileDevice = window.screen.width < 992;
  return (
    <div className={`h-100 casualLayout ${isMobileDevice ? "" : "d-flex"}`}>
      {isMobileDevice ? (
        <div className="mobileLogo bg-white">
          {landingSettings.logoStatus ? (
            <img src={landingSettings.logo} />
          ) : null}
        </div>
      ) : null}
      <div
        className={`backGroundImg ${isMobileDevice ? "h-50 mobile" : "w-50"}`}
        style={{
          background: `${
            landingSettings.imageStatus
              ? `url(${landingSettings.image}) no-repeat center top`
              : "#fff"
          }`,
        }}
      />
      <div
        className={`content ${
          isMobileDevice
            ? "h-50 mobile"
            : landingSettings.imageStatus
            ? "w-50"
            : "w-75"
        }`}
      >
        {landingSettings.logoStatus && !isMobileDevice ? (
          <img src={landingSettings.logo} />
        ) : null}

        <div
          className={`layoutTitle font-weight-bold ${
            isMobileDevice ? "mobile" : ""
          } ${!landingSettings.titleStatus ? "d-none " : ""}`}
          style={{
            color: landingSettings.titleColour,
          }}
        >
          {landingSettings.title}
        </div>
        <DescriptionText
          description={landingSettings.description}
          descriptionColour={landingSettings.descriptionColour}
          customClass={isMobileDevice ? "mobile" : ""}
          descriptionStatus={landingSettings.descriptionStatus}
        />
        <RedeemBtn
          buttonText={landingSettings.buttonText}
          buttonColour={landingSettings.buttonColour}
          buttonTextColour={landingSettings.buttonTextColour}
          customClass={`m-0 ${isMobileDevice ? "mobile" : ""}`}
          showVouchers={() => showVouchers()}
        />
        <HowToRedeemBtn landingSettings={landingSettings} />
      </div>
    </div>
  );
};

export default CasualLayout;
