// import { useCookiesStorage, useLocalStorageStore} from './storage';
import queryString from "query-string";
const cookiesDomain = process.env.REACT_APP_COOKIES_DOMAIN;

export const isLengthyArray = (item: any) =>
  Array.isArray(item) && item.length > 0;

export const getNestedValue = (
  object: any,
  propertyName: string,
  defaultValue?: any
): any => {
  const value = propertyName.split(".").reduce(getValue, object);
  return value !== undefined ? value : defaultValue;
};

export const getValue = (object: any, propertyName: string): any => {
  if (!propertyName) {
    throw new Error("Impossible to set null property");
  }
  return object === null || typeof object === "undefined"
    ? undefined
    : object[propertyName];
};

export const capitalize = (value: string) => {
  if (!value) {
    return value;
  }
  return (
    value && value.charAt(0) && value.charAt(0).toUpperCase() + value.slice(1)
  );
};

export const capitalizeEachWord = (string: string) => {
  if (!string) {
    return string;
  }
  const words =
    string &&
    string.split(" ").map((item) => {
      return capitalize(item);
    });
  return words && words.join(" ");
};

export const stripHtml = (string: string): string => {
  if (string) {
    return string
      .replace(/&#39;/g, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/&nbsp; |&lt; |&nbsp;|&gt;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/& amp;/g, "&")
      .replace(/<[^>]*>/g, "")
      .replace(/[`~!@#$^*()|+\-=?;:'".<>{}[]\\\/]/gi, "")
      .replace(/_/g, ", ");
  } else {
    return "";
  }
};

export const createMarkup = (text: string): { __html: string } => {
  return {
    __html: text
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace("/&amp;/g", "&")
      .replace(/(Â­)/gm, " "),
  };
};

export const stripPfromString = (text: string): any => {
  // text = text
  // 	.replace(/&lt;/g, "<")
  // 	.replace(/&gt;/g, ">")
  // 	.replace("/&amp;/g", "&")
  // 	.replace(/(Â­)/gm, " ");
  // return text.match(/<p>([^<]*?)<\/p>/g);
  let textArray: any = text.match(/<p>.*?<\/p>/g) || [];
  return textArray;
};

export const getCookie = (n: string): string => {
  let a: any = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
  return a ? a[1] : "";
};

export const getCookieToken = (n: string): string => {
  let a: any = `; ${document.cookie};domain= ${cookiesDomain};`.match(
    `;\\s*${n}=([^;]+)`
  );
  return a ? a[1] : "";
};

export const deleteCookie = (cookieName: string) => {
  document.cookie =
    cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const localStorageTest = () => {
  try {
    localStorage.setItem("test", "test");
    localStorage.getItem("test");
    localStorage.removeItem("test");
    return true;
  } catch (e) {
    return false;
  }
};

export const STORAGE = (key: any, value?: any, remove?: any) => {
  if (localStorageTest() === true) {
    // localstorage existance
    if (value) {
      localStorage.setItem(key, value);
      return;
    }

    let temp = localStorage.getItem(key);
    if (remove) {
      localStorage.removeItem(key);
    }
    return temp;
  } else {
    // store in browser cookies
    if (value) {
      let expiry = new Date(Number(new Date()) + 30 * 24 * 3600000).toString();
      document.cookie = key + "=" + value + ";path=/;expires=" + expiry + ";";
      return;
    }
    let all = document.cookie.split(";");
    let temp = null;
    for (let i in all) {
      let keyAndValue = all[i].split("=");
      if (keyAndValue[0].trim() === key.trim()) {
        temp = keyAndValue[1];
        break;
      }
    }
    if (remove) {
      document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    }
    return temp;
  }
};

export const createQueryString = (queryStringProps: any) => {
  //Don't show empty values in url.
  Object.keys(queryStringProps).forEach((key) => {
    if (!queryStringProps[key] && !(queryStringProps[key] === 0)) {
      delete queryStringProps[key];
    }
  });
  return Object.keys(queryStringProps)
    .filter((item) => item)
    .map((key) => {
      if (Array.isArray(queryStringProps[key])) {
        return queryStringProps[key]
          .map((item: any) => `${key}[]=${encodeURIComponent(item)}`)
          .join("&");
      }
      return `${key}=${encodeURIComponent(queryStringProps[key])}`;
    })
    .filter((item) => item)
    .join("&");
};

export const getUrlQueryParams = (req?: { search: string }) => {
  let searchObject = "";
  searchObject = window.location.search;

  return queryString.parse(searchObject, {
    arrayFormat: "bracket",
  });
};

export const convertToSlug = (Text: string) => {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const amountConversion = (
  conversionFactor: number,
  amount: number,
  currencyValue: number
) => {
  amount = (amount * currencyValue * 100) / conversionFactor;
  return Math.round(amount) / 100;
};

export const isValidEmailId = (email: string) => {
  // let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,3})+$/;
  let mailformat =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return email.match(mailformat);
};

export const setCookie = (sso_token: any) => {
  document.cookie = sso_token;
  window.location.replace("/");
};

export const numberFormat = (value: number) => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isMobile = (): boolean => window.innerWidth < 991;
