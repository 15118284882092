import React, { useEffect, useRef, useState } from "react";
import "./language-switch-popup.scss";

const languageArray = [
  { code: "he", name: "Hebrew" },
  { code: "zh", name: "Chinese (Simplified)" },
  { code: "en", name: "English" },
  { code: "tw", name: "Chinese (Traditional)" },
  { code: "pl", name: "Polish" },
  { code: "de", name: "German" },
  { code: "pt", name: "Portuguese" },
  { code: "es", name: "Spanish" },
  { code: "th", name: "Thai" },
  { code: "ja", name: "Japanese" },
  { code: "it", name: "Italian" },
  { code: "fr", name: "French" },
  { code: "ko", name: "Korean" },
];

const LanguagesPopup = ({ onlyXoxoCode = false }: any) => {
  const ref = useRef<any>(null);
  const [showLanguageSwitchPopup, setShowLanguageSwitchPopup] = useState(false);
  const [selectedLang, setSelectedLang] = useState<string>("en");

  useEffect(() => {
    const currentLang: string = localStorage.getItem("wglang") || "";
    let selectedLang = currentLang ? currentLang : "en";
    setSelectedLang(selectedLang);
  }, []);

  const changeLang = (lngTo: string = "") => {
    localStorage.setItem("wglang", lngTo);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowLanguageSwitchPopup(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const currentLang = languageArray.find(
    (language) => language.code === selectedLang
  ) || {
    code: "en",
    name: "English",
  };

  return (
    <div
      className={
        onlyXoxoCode
          ? "navbar-options navbar-language navbar-options-onlyxoxocode"
          : "navbar-options navbar-language"
      }
      onClick={() => {
        setShowLanguageSwitchPopup(!showLanguageSwitchPopup);
      }}
      ref={ref}
    >
      <img className="" src="/img/icons/language.svg" alt="" />
      <span>{currentLang.name || ""}</span>
      <img
        className={showLanguageSwitchPopup ? "navbar-language-arrow-flip" : ""}
        src="/img/icons/bottom-arrow.svg"
        alt=""
      />
      {showLanguageSwitchPopup && (
        <div className="language-switch-popup-container">
          <div className="languages-popup">
            <div className="languages-popup-title">Select Language</div>
            {languageArray.map((language) => (
              <div
                className={
                  selectedLang === language.code
                    ? "languages-popup-option languages-popup-option-selected"
                    : "languages-popup-option"
                }
                key={language.code}
                onClick={() => changeLang(language.code)}
              >
                {language.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguagesPopup;
