import React from "react";
import Layout from "../../commons/components/Layout/index";

class PageNotFound extends React.Component {
  render() {
    return (
      <div
        className="h-100 w-100"
        style={{
          background: "white",
          position: "fixed",
          top: "0px",
          left: "0px",
          bottom: "0px",
          right: "0px",
          overflow: "auto",
        }}
      >
        <div
          className="text-center h-100"
          style={{ paddingBottom: "100px", paddingTop: "100px" }}
        >
          <img
            alt=""
            src="/img/notfound.svg"
            style={{ maxWidth: "100%", minWidth: "300px", height: "100%" }}
          />
        </div>
      </div>
    );
  }
}
export default PageNotFound;
