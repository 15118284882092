import React from "react";
import DescriptionText from "../components/DescriptionText";
import HowToRedeemBtn from "../components/HowToRedeemBtn";
import RedeemBtn from "../components/RedeemBtn";
import "./style.scss";

interface LayoutProps {
  landingSettings: any;
  showVouchers: Function;
}

const FunLayout: React.FC<LayoutProps> = ({
  landingSettings,
  showVouchers,
}) => {
  const isMobileDevice = window.screen.width < 992;
  return (
    <div className="h-100 funLayout">
      <div
        className="backGroundImg"
        style={{
          background: `${
            landingSettings.imageStatus
              ? `url(${landingSettings.image}) no-repeat center top`
              : "#fff"
          }`,
        }}
      />
      <div className="bottomPart bg-white">
        <div
          className={`content position-relative text-center pt-0 ${
            isMobileDevice ? "mobile" : ""
          }`}
        >
          {landingSettings.logoStatus ? (
            <span className="logoHeader">
              <img src={landingSettings.logo} />
            </span>
          ) : null}
          <div className="mx-3 pb-3 d-flex flex-column align-items-center justify-content-center h-100">
            <div
              className={`layoutTitle font-weight-bold ${
                isMobileDevice ? "mobile pt-4" : ""
              } ${!landingSettings.titleStatus ? "d-none " : ""}`}
              style={{
                color: landingSettings.titleColour,
              }}
            >
              {landingSettings.title}
            </div>
            <DescriptionText
              description={landingSettings.description}
              customClass={isMobileDevice ? "mobile" : ""}
              descriptionColour={landingSettings.descriptionColour}
              descriptionStatus={landingSettings.descriptionStatus}
            />
            <RedeemBtn
              buttonText={landingSettings.buttonText}
              buttonColour={landingSettings.buttonColour}
              buttonTextColour={landingSettings.buttonTextColour}
              customClass={isMobileDevice ? "mobile" : ""}
              showVouchers={() => showVouchers()}
            />
            <HowToRedeemBtn landingSettings={landingSettings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunLayout;
