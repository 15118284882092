import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Col,
} from "reactstrap";
import "./special-redemption-modal.scss";
import ButtonLoader from "../../../../commons/components/Loader/ButtonLoader";

interface RedemptionProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  collectVoucher: any;
  cancelVoucher: any;
  itemName: String;
}

interface State {
  checked: boolean;
}

const SpecialRedemptionModal = ({
  showModal,
  setShowModal,
  collectVoucher,
  cancelVoucher,
  itemName,
}: RedemptionProps) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
    cancelVoucher();
  };
  const handleContinue = () => {
    setLoading(true);
    collectVoucher();
  };

  return (
    <>
      <Modal
        size="sm"
        isOpen={showModal}
        className={`voucher-modal redemption-modal `}
        fade={false}
        toggle={toggleModal}
        scrollable
        backdrop={true}
      >
        <ModalBody className="redemption-modal-body">
          <div className="redemption-modal-header">
            <div> Special Redemption Instructions</div>
            <span
              className="redemption-modal-header-close-icon"
              onClick={toggleModal}
            >
              <img src="/img/icons/cross.svg" alt="icon" />
            </span>
          </div>
          <ol>
            <li>
              Amount will be directly transferred to your {itemName} account for
              which you have placed the order as per the denominations
              available.
            </li>
            <li>
              The Money in Gift Sub Wallet cannot be transferred to Bank Account
              Nor Peer Peer transfer can happen.
            </li>
            <li>
              Please note the mobile no. which is entered while placing order to
              the same no. the money will be added.
            </li>
            <li>One lakh is the maximum transaction can be done in a month.</li>
          </ol>
          <div
            className="checkbox"
            onClick={() => {
              setChecked(!checked);
            }}
          >
            {checked ? (
              <img src="/img/icons/checkbox-marked.svg" />
            ) : (
              <img src="/img/icons/checkbox-blank-outline.svg" />
            )}
            <span>I understand the redemption instructions.</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Col className="text-right">
            <a onClick={toggleModal} className="cancel-btn">
              Cancel
            </a>
            <Button
              className="continue-btn"
              disabled={!checked || loading}
              onClick={handleContinue}
            >
              {loading ? <ButtonLoader /> : "Collect Voucher"}
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SpecialRedemptionModal;
