import React from "react";
import { connect } from "react-redux";

interface AppProps {}

interface AppState {}

class Root extends React.Component<AppProps, AppState> {
  render() {
    return (
      <>
        {/* {this.state.isLoading  || this.state.isFetchingUser ? <PageLoader /> : this.props.children } */}
        {this.props.children}
      </>
    );
  }
}

export default Root;
