import React, { useState } from "react";
// import {CLOUDINARY_URL} from '../../../../commons/constants'
import "../../home.scss";
import { Row, Col } from "reactstrap";

export default function Card({
  item,
  i,
  handleClick,
  data,
  selected,
  xc_selected,
}: any) {
  return (
    <div
      className={`  grid-item
                            ${
                              data.length >= 5 && !(selected === i + 1)
                                ? "width-19"
                                : ""
                            } block
                            ${data.length === 1 ? "position-relative" : ""}
                        `}
      key={i}
    >
      <div className="inner-grid">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClick(selected + i, data.length);
          }}
          className="card-parent"
        >
          <Row>
            <Col lg="12" xs="12">
              <div className="card_logo">
                {/* <img src={CLOUDINARY_URL + item.logo} alt='logo' /> */}
                <img src={item.image} alt="logo" />
              </div>
            </Col>
            <Col lg="12" xs="12">
              <div className="title-block">
                <h2>{item.name ? item.name : ""}</h2>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
