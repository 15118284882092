export const DEFAULT_CURRENCY_CODE = "INR";
export const CLOUDINARY_URL =
  "https://res.cloudinary.com/dyyjph6kx/w_auto,q_auto,f_auto/";
export const DEFAULT_COUNTRY_ID = 99;
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_COUNTRY_NAME = "India";
export const DEFAULT_CITY_ID = 1319;
export const DEFAULT_CITY_NAME = "Bangalore";
export const LOGIN_COOKIE_NAME = "s_e_t";
export const SINGLE_PRICE_CART_LIMIT = 50;
export const ADNOC = "ADNOC";
export const GTM_ID = "GTM-TRZ23JQ";
export const GTM_VISITOR_LOG = "visitorLog";
export const GTM_PAYMENT_SUCCESS = "paymentSuccess";
export const XOXO_LINK_OTP_SMS_ACTION = "XOXOLINK_OTP";
export const GVLINK_OTP = "GVLINK_OTP";
export const DEFAULT_SELECTED_COUNTRY = {
  name: "India",
  country_id: 99,
  iso_code_2: "IN",
  iso_code_3: "IND",
};
export const DEFAULT_BRANDING = {
  logo_url: "",
  custom_route: "/:keyword",
  custom_url: "/",
};
export const DEFAULT_PLATFORM_SETTINGS = {
  urlPrefix: "",
  companyName: "",
  customText: {
    empulsPoints: "Points",
    payNowButton: "Pay Now",
  },
  paymentMethodAccess: {
    empulsePoints: 1,
    gcb: 1,
    tokens: 1,
    voucher: 1,
    payu: 1,
  },
  primaryLogo: "",
  primaryColor: "#66286B",
  adminPanelAccess: 1,
};

export const MOBILE_NO_ERROR =
  "Mobile number should be between 7 to 16 digits only";
