import React from "react";
import DescriptionText from "../components/DescriptionText";
import HowToRedeemBtn from "../components/HowToRedeemBtn";
import RedeemBtn from "../components/RedeemBtn";
import "./style.scss";

interface LayoutProps {
  landingSettings: any;
  showVouchers: Function;
}

const GrandLayout: React.FC<LayoutProps> = ({
  landingSettings,
  showVouchers,
}) => {
  const isMobileDevice = window.screen.width < 992;

  const isGif =
    landingSettings &&
    landingSettings.image &&
    landingSettings.image.endsWith(".gif");

  return (
    <div className="h-100 grandLayout">
      {landingSettings.logoStatus ? (
        <div
          className={`landingHeader bg-white text-center ${
            isMobileDevice ? "mobile" : ""
          }`}
        >
          <img src={landingSettings.logo} />
        </div>
      ) : null}

      <div
        className={`previewBody ${
          !landingSettings.logoStatus ? "noLogo" : ""
        } ${isMobileDevice ? "mobile" : ""}`}
        style={{
          background: `${
            landingSettings.imageStatus
              ? `url(${landingSettings.image}) no-repeat center top`
              : "#fff"
          }`,
          maxWidth: isGif ? "500px" : "",
        }}
      >
        <div className={`text-center content ${isMobileDevice ? "px-3" : ""}`}>
          <div
            className={`layoutTitle ${isMobileDevice ? "mobile" : ""} ${
              !landingSettings.titleStatus ? "d-none " : ""
            }`}
            style={{
              color: landingSettings.titleColour,
            }}
          >
            {landingSettings.title}
          </div>
          <DescriptionText
            description={landingSettings.description}
            descriptionColour={landingSettings.descriptionColour}
            customClass={isMobileDevice ? "mobile" : ""}
            descriptionStatus={landingSettings.descriptionStatus}
          />
          <RedeemBtn
            buttonText={landingSettings.buttonText}
            buttonColour={landingSettings.buttonColour}
            buttonTextColour={landingSettings.buttonTextColour}
            customClass={isMobileDevice ? "mobile" : ""}
            showVouchers={() => showVouchers()}
          />
          <HowToRedeemBtn landingSettings={landingSettings} />
        </div>
      </div>
    </div>
  );
};

export default GrandLayout;
