import React from "react";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bootstrap from "./Bootstrap";
// import BrowserRouter from 'react-router-dom'
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import configureStore from "./store";
// import * as Sentry from '@sentry/browser';
// const { persistor, store } = configureStore();

const App: React.FC = () => {
  return (
    <div id="xoxoday-webapp">
      <Bootstrap>
        <Routes />
        <ToastContainer />
      </Bootstrap>
    </div>
  );
};

export default App;
