import React, { useState, useEffect, memo, useRef } from "react";
// import {CLOUDINARY_URL} from '../../../../commons/constants'
import "../../home.scss";
import TncBack from "./TncBack";
import ButtonLoader from "../../../../commons/components/Loader/ButtonLoader";
import CollectVoucherCard from "./CollectVoucherCard";
import OtpInput from "react-otp-input";
import { Modal, ModalBody, ModalHeader, Row, Col, Input } from "reactstrap";
import Select, { components } from "react-select";
import { phone_codes } from "../../../../utils/locales";
import axios from "axios";
import { MOBILE_NO_ERROR } from "../../../../commons/constants/app.constants";
import {
  checkGivenEmailIsValid,
  checkGivenMobileNumberIsValid,
  makeRequestAuth,
} from "../../../../utils/globals";
const TEMPLATE_ID = "link_redemption_otp";
const SUBJECT = "OTP to access Xoxo Link reward";

interface PostParams {
  isd_code: string;
  phone: string;
  email: string;
  otp_id?: string;
  otp?: string;
  template_id?: string;
  subject?: string;
}

export default memo(function CardListModal({
  item,
  selected,
  removeSelected,
  data,
  handleHtml,
  campaign,
  isUserVerified,
  redemption_check,
  customStyles,
  xc_selected,
  xc_data,
  code = "",
  handleModal,
  delivery_method,
  linkCode = "",
  otpId,
  setOtpId,
  setShowSpecialRedemptionModal,
  onlyXoxoCode = false,
  showCts,
  loading,
  authenticate_email,
  maskedEmail,
  authenticate_phone,
  maskedPhone,
  isGvLink,
  country_name,
  filter_group_id,
}: any) {
  const [showTnc, setShowTnc] = useState(false);

  const toggleTnc = () => {
    setShowTnc((prevState) => {
      return !prevState;
    });
  };
  return (
    <>
      <Modal
        size="lg"
        isOpen={selected > 0}
        className={`voucher-modal ${
          onlyXoxoCode ? "voucher-modal-shadow  " : ""
        } ${showTnc ? "d-none" : ""}`}
        toggle={removeSelected}
        fade={false}
        backdrop={!onlyXoxoCode}
        modalClassName="class-list-modal"
      >
        {!otpId ? (
          <span className="close-icon" onClick={removeSelected}>
            <img src="/img/icons/close-icon-light.svg" alt="icon" />
          </span>
        ) : null}
        <ModalBody className="voucher-modal-body">
          {/* <div>
      <Button onClick={self.removeSelected} close />
    </div> */}
          <CollectVoucherCard
            delivery_method={delivery_method}
            linkCode={linkCode}
            xc_selected={xc_selected}
            handleModal={handleModal}
            selected={selected}
            item={item}
            handleHtml={handleHtml}
            campaign={campaign}
            isUserVerified={isUserVerified}
            redemption_check={redemption_check}
            authenticate_email={authenticate_email}
            maskedEmail={maskedEmail}
            authenticate_phone={authenticate_phone}
            maskedPhone={maskedPhone}
            customStyles={customStyles}
            otpId={otpId}
            setOtpId={setOtpId}
            toggleTnc={toggleTnc}
            verify_mobile={item.verify_mobile}
            setShowSpecialRedemptionModal={setShowSpecialRedemptionModal}
            showCts={showCts}
            loading={loading}
            isGvLink={isGvLink}
            country_name={country_name}
            filter_group_id={filter_group_id}
          />
        </ModalBody>
      </Modal>
      {showTnc && (
        <TncBack
          showTnc={showTnc}
          toggleTnc={toggleTnc}
          data={xc_selected ? xc_data : item ? item : data[0]}
        />
      )}
    </>
  );
});
