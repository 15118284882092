export const phone_codes: any[] = [
  {
    country_id: 244,
    name: "Aaland Islands",
    phonecode: "+35818",
    iso: "AX",
  },
  {
    country_id: 1,
    name: "Afghanistan",
    phonecode: "+93",
    iso: "AF",
  },
  {
    country_id: 2,
    name: "Albania",
    phonecode: "+355",
    iso: "AL",
  },
  {
    country_id: 3,
    name: "Algeria",
    phonecode: "+213",
    iso: "DZ",
  },
  {
    country_id: 4,
    name: "American Samoa",
    phonecode: "+1684",
    iso: "AS",
  },
  {
    country_id: 5,
    name: "Andorra",
    phonecode: "+376",
    iso: "AD",
  },
  {
    country_id: 6,
    name: "Angola",
    phonecode: "+244",
    iso: "AO",
  },
  {
    country_id: 7,
    name: "Anguilla",
    phonecode: "+1264",
    iso: "AI",
  },
  {
    country_id: 8,
    name: "Antarctica",
    phonecode: "+6721",
    iso: "AQ",
  },
  {
    country_id: 9,
    name: "Antigua and Barbuda",
    phonecode: "+1268",
    iso: "AG",
  },
  {
    country_id: 10,
    name: "Argentina",
    phonecode: "+54",
    iso: "AR",
  },
  {
    country_id: 11,
    name: "Armenia",
    phonecode: "+374",
    iso: "AM",
  },
  {
    country_id: 12,
    name: "Aruba",
    phonecode: "+297",
    iso: "AW",
  },
  {
    country_id: 13,
    name: "Australia",
    phonecode: "+61",
    iso: "AU",
  },
  {
    country_id: 14,
    name: "Austria",
    phonecode: "+43",
    iso: "AT",
  },
  {
    country_id: 15,
    name: "Azerbaijan",
    phonecode: "+994",
    iso: "AZ",
  },
  {
    country_id: 16,
    name: "Bahamas",
    phonecode: "+1242",
    iso: "BS",
  },
  {
    country_id: 17,
    name: "Bahrain",
    phonecode: "+973",
    iso: "BH",
  },
  {
    country_id: 18,
    name: "Bangladesh",
    phonecode: "+880",
    iso: "BD",
  },
  {
    country_id: 19,
    name: "Barbados",
    phonecode: "+1246",
    iso: "BB",
  },
  {
    country_id: 20,
    name: "Belarus",
    phonecode: "+375",
    iso: "BY",
  },
  {
    country_id: 21,
    name: "Belgium",
    phonecode: "+32",
    iso: "BE",
  },
  {
    country_id: 22,
    name: "Belize",
    phonecode: "+501",
    iso: "BZ",
  },
  {
    country_id: 23,
    name: "Benin",
    phonecode: "+229",
    iso: "BJ",
  },
  {
    country_id: 24,
    name: "Bermuda",
    phonecode: "+1441",
    iso: "BM",
  },
  {
    country_id: 25,
    name: "Bhutan",
    phonecode: "+975",
    iso: "BT",
  },
  {
    country_id: 26,
    name: "Bolivia",
    phonecode: "+591",
    iso: "BO",
  },
  {
    country_id: 27,
    name: "Bosnia and Herzegovina",
    phonecode: "+387",
    iso: "BA",
  },
  {
    country_id: 28,
    name: "Botswana",
    phonecode: "+267",
    iso: "BW",
  },
  {
    country_id: 30,
    name: "Brazil",
    phonecode: "+55",
    iso: "BR",
  },
  {
    country_id: 31,
    name: "British Indian Ocean Territory",
    phonecode: "+246",
    iso: "IO",
  },
  {
    country_id: 32,
    name: "Brunei Darussalam",
    phonecode: "+673",
    iso: "BN",
  },
  {
    country_id: 33,
    name: "Bulgaria",
    phonecode: "+359",
    iso: "BG",
  },
  {
    country_id: 34,
    name: "Burkina Faso",
    phonecode: "+226",
    iso: "BF",
  },
  {
    country_id: 35,
    name: "Burundi",
    phonecode: "+257",
    iso: "BI",
  },
  {
    country_id: 36,
    name: "Cambodia",
    phonecode: "+855",
    iso: "KH",
  },
  {
    country_id: 37,
    name: "Cameroon",
    phonecode: "+237",
    iso: "CM",
  },
  {
    country_id: 38,
    name: "Canada",
    phonecode: "+1",
    iso: "CA",
  },
  {
    country_id: 39,
    name: "Cape Verde",
    phonecode: "+238",
    iso: "CV",
  },
  {
    country_id: 40,
    name: "Cayman Islands",
    phonecode: "+1345",
    iso: "KY",
  },
  {
    country_id: 41,
    name: "Central African Republic",
    phonecode: "+236",
    iso: "CF",
  },
  {
    country_id: 42,
    name: "Chad",
    phonecode: "+235",
    iso: "TD",
  },
  {
    country_id: 43,
    name: "Chile",
    phonecode: "+56",
    iso: "CL",
  },
  {
    country_id: 44,
    name: "China",
    phonecode: "+86",
    iso: "CN",
  },
  {
    country_id: 45,
    name: "Christmas Island",
    phonecode: "+61",
    iso: "CX",
  },
  {
    country_id: 46,
    name: "Cocos (Keeling) Islands",
    phonecode: "+61",
    iso: "CC",
  },
  {
    country_id: 47,
    name: "Colombia",
    phonecode: "+57",
    iso: "CO",
  },
  {
    country_id: 48,
    name: "Comoros",
    phonecode: "+269",
    iso: "KM",
  },
  {
    country_id: 49,
    name: "Congo",
    phonecode: "+242",
    iso: "CG",
  },
  {
    country_id: 50,
    name: "Cook Islands",
    phonecode: "+682",
    iso: "CK",
  },
  {
    country_id: 51,
    name: "Costa Rica",
    phonecode: "+506",
    iso: "CR",
  },
  {
    country_id: 52,
    name: "Cote D'Ivoire",
    phonecode: "+225",
    iso: "CI",
  },
  {
    country_id: 53,
    name: "Croatia",
    phonecode: "+385",
    iso: "HR",
  },
  {
    country_id: 54,
    name: "Cuba",
    phonecode: "+53",
    iso: "CU",
  },
  {
    country_id: 55,
    name: "Cyprus",
    phonecode: "+357",
    iso: "CY",
  },
  {
    country_id: 56,
    name: "Czech Republic",
    phonecode: "+420",
    iso: "CZ",
  },
  {
    country_id: 237,
    name: "Democratic Republic of Congo",
    phonecode: "+243",
    iso: "CD",
  },
  {
    country_id: 57,
    name: "Denmark",
    phonecode: "+45",
    iso: "DK",
  },
  {
    country_id: 58,
    name: "Djibouti",
    phonecode: "+253",
    iso: "DJ",
  },
  {
    country_id: 59,
    name: "Dominica",
    phonecode: "+1767",
    iso: "DM",
  },
  {
    country_id: 60,
    name: "Dominican Republic",
    phonecode: "+1829",
    iso: "DO",
  },
  {
    country_id: 62,
    name: "Ecuador",
    phonecode: "+593",
    iso: "EC",
  },
  {
    country_id: 63,
    name: "Egypt",
    phonecode: "+20",
    iso: "EG",
  },
  {
    country_id: 64,
    name: "El Salvador",
    phonecode: "+503",
    iso: "SV",
  },
  {
    country_id: 65,
    name: "Equatorial Guinea",
    phonecode: "+240",
    iso: "GQ",
  },
  {
    country_id: 66,
    name: "Eritrea",
    phonecode: "+291",
    iso: "ER",
  },
  {
    country_id: 67,
    name: "Estonia",
    phonecode: "+372",
    iso: "EE",
  },
  {
    country_id: 68,
    name: "Ethiopia",
    phonecode: "+251",
    iso: "ET",
  },
  {
    country_id: 69,
    name: "Falkland Islands (Malvinas)",
    phonecode: "+500",
    iso: "FK",
  },
  {
    country_id: 70,
    name: "Faroe Islands",
    phonecode: "+298",
    iso: "FO",
  },
  {
    country_id: 71,
    name: "Fiji",
    phonecode: "+679",
    iso: "FJ",
  },
  {
    country_id: 72,
    name: "Finland",
    phonecode: "+358",
    iso: "FI",
  },
  {
    country_id: 74,
    name: "France, Metropolitan",
    phonecode: "+33",
    iso: "FR",
  },
  {
    country_id: 75,
    name: "French Guiana",
    phonecode: "+594",
    iso: "GF",
  },
  {
    country_id: 76,
    name: "French Polynesia",
    phonecode: "+689",
    iso: "PF",
  },
  {
    country_id: 126,
    name: "FYROM",
    phonecode: "+389",
    iso: "MK",
  },
  {
    country_id: 78,
    name: "Gabon",
    phonecode: "+241",
    iso: "GA",
  },
  {
    country_id: 79,
    name: "Gambia",
    phonecode: "+220",
    iso: "GM",
  },
  {
    country_id: 80,
    name: "Georgia",
    phonecode: "+995",
    iso: "GE",
  },
  {
    country_id: 81,
    name: "Germany",
    phonecode: "+49",
    iso: "DE",
  },
  {
    country_id: 82,
    name: "Ghana",
    phonecode: "+233",
    iso: "GH",
  },
  {
    country_id: 83,
    name: "Gibraltar",
    phonecode: "+350",
    iso: "GI",
  },
  {
    country_id: 84,
    name: "Greece",
    phonecode: "+30",
    iso: "GR",
  },
  {
    country_id: 85,
    name: "Greenland",
    phonecode: "+299",
    iso: "GL",
  },
  {
    country_id: 86,
    name: "Grenada",
    phonecode: "+1473",
    iso: "GD",
  },
  {
    country_id: 87,
    name: "Guadeloupe",
    phonecode: "+590",
    iso: "GP",
  },
  {
    country_id: 88,
    name: "Guam",
    phonecode: "+1671",
    iso: "GU",
  },
  {
    country_id: 89,
    name: "Guatemala",
    phonecode: "+502",
    iso: "GT",
  },
  {
    country_id: 241,
    name: "Guernsey",
    phonecode: "+44",
    iso: "GG",
  },
  {
    country_id: 90,
    name: "Guinea",
    phonecode: "+224",
    iso: "GN",
  },
  {
    country_id: 91,
    name: "Guinea-Bissau",
    phonecode: "+245",
    iso: "GW",
  },
  {
    country_id: 92,
    name: "Guyana",
    phonecode: "+592",
    iso: "GY",
  },
  {
    country_id: 93,
    name: "Haiti",
    phonecode: "+509",
    iso: "HT",
  },
  {
    country_id: 95,
    name: "Honduras",
    phonecode: "+504",
    iso: "HN",
  },
  {
    country_id: 96,
    name: "Hong Kong",
    phonecode: "+852",
    iso: "HK",
  },
  {
    country_id: 97,
    name: "Hungary",
    phonecode: "+36",
    iso: "HU",
  },
  {
    country_id: 98,
    name: "Iceland",
    phonecode: "+354",
    iso: "IS",
  },
  {
    country_id: 99,
    name: "India",
    phonecode: "+91",
    iso: "IN",
  },
  {
    country_id: 100,
    name: "Indonesia",
    phonecode: "+62",
    iso: "ID",
  },
  {
    country_id: 101,
    name: "Iran",
    phonecode: "+98",
    iso: "IR",
  },
  {
    country_id: 102,
    name: "Iraq",
    phonecode: "+964",
    iso: "IQ",
  },
  {
    country_id: 103,
    name: "Ireland",
    phonecode: "+353",
    iso: "IE",
  },
  {
    country_id: 104,
    name: "Israel",
    phonecode: "+972",
    iso: "IL",
  },
  {
    country_id: 105,
    name: "Italy",
    phonecode: "+39",
    iso: "IT",
  },
  {
    country_id: 106,
    name: "Jamaica",
    phonecode: "+1876",
    iso: "JM",
  },
  {
    country_id: 107,
    name: "Japan",
    phonecode: "+81",
    iso: "JP",
  },
  {
    country_id: 240,
    name: "Jersey",
    phonecode: "+44",
    iso: "JE",
  },
  {
    country_id: 108,
    name: "Jordan",
    phonecode: "+962",
    iso: "JO",
  },
  {
    country_id: 109,
    name: "Kazakhstan",
    phonecode: "+7",
    iso: "KZ",
  },
  {
    country_id: 110,
    name: "Kenya",
    phonecode: "+254",
    iso: "KE",
  },
  {
    country_id: 111,
    name: "Kiribati",
    phonecode: "+686",
    iso: "KI",
  },
  {
    country_id: 113,
    name: "Korea, Republic of",
    phonecode: "+82",
    iso: "KR",
  },
  {
    country_id: 114,
    name: "Kuwait",
    phonecode: "+965",
    iso: "KW",
  },
  {
    country_id: 115,
    name: "Kyrgyzstan",
    phonecode: "+996",
    iso: "KG",
  },
  {
    country_id: 116,
    name: "Lao People's Democratic Republic",
    phonecode: "+856",
    iso: "LA",
  },
  {
    country_id: 117,
    name: "Latvia",
    phonecode: "+371",
    iso: "LV",
  },
  {
    country_id: 118,
    name: "Lebanon",
    phonecode: "+961",
    iso: "LB",
  },
  {
    country_id: 119,
    name: "Lesotho",
    phonecode: "+266",
    iso: "LS",
  },
  {
    country_id: 120,
    name: "Liberia",
    phonecode: "+231",
    iso: "LR",
  },
  {
    country_id: 121,
    name: "Libyan Arab Jamahiriya",
    phonecode: "+218",
    iso: "LY",
  },
  {
    country_id: 122,
    name: "Liechtenstein",
    phonecode: "+423",
    iso: "LI",
  },
  {
    country_id: 123,
    name: "Lithuania",
    phonecode: "+370",
    iso: "LT",
  },
  {
    country_id: 124,
    name: "Luxembourg",
    phonecode: "+352",
    iso: "LU",
  },
  {
    country_id: 125,
    name: "Macau",
    phonecode: "+853",
    iso: "MO",
  },
  {
    country_id: 127,
    name: "Madagascar",
    phonecode: "+261",
    iso: "MG",
  },
  {
    country_id: 128,
    name: "Malawi",
    phonecode: "+265",
    iso: "MW",
  },
  {
    country_id: 129,
    name: "Malaysia",
    phonecode: "+60",
    iso: "MY",
  },
  {
    country_id: 130,
    name: "Maldives",
    phonecode: "+960",
    iso: "MV",
  },
  {
    country_id: 131,
    name: "Mali",
    phonecode: "+223",
    iso: "ML",
  },
  {
    country_id: 132,
    name: "Malta",
    phonecode: "+356",
    iso: "MT",
  },
  {
    country_id: 133,
    name: "Marshall Islands",
    phonecode: "+692",
    iso: "MH",
  },
  {
    country_id: 134,
    name: "Martinique",
    phonecode: "+596",
    iso: "MQ",
  },
  {
    country_id: 135,
    name: "Mauritania",
    phonecode: "+222",
    iso: "MR",
  },
  {
    country_id: 136,
    name: "Mauritius",
    phonecode: "+230",
    iso: "MU",
  },
  {
    country_id: 137,
    name: "Mayotte",
    phonecode: "+262",
    iso: "YT",
  },
  {
    country_id: 138,
    name: "Mexico",
    phonecode: "+52",
    iso: "MX",
  },
  {
    country_id: 139,
    name: "Micronesia, Federated States of",
    phonecode: "+691",
    iso: "FM",
  },
  {
    country_id: 140,
    name: "Moldova, Republic of",
    phonecode: "+373",
    iso: "MD",
  },
  {
    country_id: 141,
    name: "Monaco",
    phonecode: "+377",
    iso: "MC",
  },
  {
    country_id: 142,
    name: "Mongolia",
    phonecode: "+976",
    iso: "MN",
  },
  {
    country_id: 242,
    name: "Montenegro",
    phonecode: "+382",
    iso: "ME",
  },
  {
    country_id: 143,
    name: "Montserrat",
    phonecode: "+1664",
    iso: "MS",
  },
  {
    country_id: 144,
    name: "Morocco",
    phonecode: "+212",
    iso: "MA",
  },
  {
    country_id: 145,
    name: "Mozambique",
    phonecode: "+258",
    iso: "MZ",
  },
  {
    country_id: 146,
    name: "Myanmar",
    phonecode: "+95",
    iso: "MM",
  },
  {
    country_id: 147,
    name: "Namibia",
    phonecode: "+264",
    iso: "NA",
  },
  {
    country_id: 148,
    name: "Nauru",
    phonecode: "+674",
    iso: "NR",
  },
  {
    country_id: 149,
    name: "Nepal",
    phonecode: "+977",
    iso: "NP",
  },
  {
    country_id: 150,
    name: "Netherlands",
    phonecode: "+31",
    iso: "NL",
  },
  {
    country_id: 151,
    name: "Netherlands Antilles",
    phonecode: "+599",
    iso: "AN",
  },
  {
    country_id: 152,
    name: "New Caledonia",
    phonecode: "+687",
    iso: "NC",
  },
  {
    country_id: 153,
    name: "New Zealand",
    phonecode: "+64",
    iso: "NZ",
  },
  {
    country_id: 154,
    name: "Nicaragua",
    phonecode: "+505",
    iso: "NI",
  },
  {
    country_id: 155,
    name: "Niger",
    phonecode: "+227",
    iso: "NE",
  },
  {
    country_id: 156,
    name: "Nigeria",
    phonecode: "+234",
    iso: "NG",
  },
  {
    country_id: 157,
    name: "Niue",
    phonecode: "+683",
    iso: "NU",
  },
  {
    country_id: 158,
    name: "Norfolk Island",
    phonecode: "+672",
    iso: "NF",
  },
  {
    country_id: 112,
    name: "North Korea",
    phonecode: "+850",
    iso: "KP",
  },
  {
    country_id: 159,
    name: "Northern Mariana Islands",
    phonecode: "+1670",
    iso: "MP",
  },
  {
    country_id: 160,
    name: "Norway",
    phonecode: "+47",
    iso: "NO",
  },
  {
    country_id: 161,
    name: "Oman",
    phonecode: "+968",
    iso: "OM",
  },
  {
    country_id: 162,
    name: "Pakistan",
    phonecode: "+92",
    iso: "PK",
  },
  {
    country_id: 163,
    name: "Palau",
    phonecode: "+680",
    iso: "PW",
  },
  {
    country_id: 247,
    name: "Palestinian Territory, Occupied",
    phonecode: "+970",
    iso: "PS",
  },
  {
    country_id: 164,
    name: "Panama",
    phonecode: "+507",
    iso: "PA",
  },
  {
    country_id: 165,
    name: "Papua New Guinea",
    phonecode: "+675",
    iso: "PG",
  },
  {
    country_id: 166,
    name: "Paraguay",
    phonecode: "+595",
    iso: "PY",
  },
  {
    country_id: 167,
    name: "Peru",
    phonecode: "+51",
    iso: "PE",
  },
  {
    country_id: 168,
    name: "Philippines",
    phonecode: "+63",
    iso: "PH",
  },
  {
    country_id: 169,
    name: "Pitcairn",
    phonecode: "+870",
    iso: "PN",
  },
  {
    country_id: 170,
    name: "Poland",
    phonecode: "+48",
    iso: "PL",
  },
  {
    country_id: 171,
    name: "Portugal",
    phonecode: "+351",
    iso: "PT",
  },
  {
    country_id: 172,
    name: "Puerto Rico",
    phonecode: "+1",
    iso: "PR",
  },
  {
    country_id: 173,
    name: "Qatar",
    phonecode: "+974",
    iso: "QA",
  },
  {
    country_id: 174,
    name: "Reunion",
    phonecode: "+262",
    iso: "RE",
  },
  {
    country_id: 175,
    name: "Romania",
    phonecode: "+40",
    iso: "RO",
  },
  {
    country_id: 176,
    name: "Russian Federation",
    phonecode: "+7",
    iso: "RU",
  },
  {
    country_id: 177,
    name: "Rwanda",
    phonecode: "+250",
    iso: "RW",
  },
  {
    country_id: 197,
    name: "Saint Helena",
    phonecode: "+290",
    iso: "SH",
  },
  {
    country_id: 178,
    name: "Saint Kitts and Nevis",
    phonecode: "+1869",
    iso: "KN",
  },
  {
    country_id: 179,
    name: "Saint Lucia",
    phonecode: "+1758",
    iso: "LC",
  },
  {
    country_id: 179,
    name: "Saint Martin",
    phonecode: "+1599",
    iso: "MF",
  },
  {
    country_id: 198,
    name: "Saint Pierre and Miquelon",
    phonecode: "+508",
    iso: "PM",
  },
  {
    country_id: 180,
    name: "Saint Vincent and the Grenadines",
    phonecode: "+1784",
    iso: "VC",
  },
  {
    country_id: 181,
    name: "Samoa",
    phonecode: "+685",
    iso: "WS",
  },
  {
    country_id: 182,
    name: "San Marino",
    phonecode: "+378",
    iso: "SM",
  },
  {
    country_id: 183,
    name: "Sao Tome and Principe",
    phonecode: "+239",
    iso: "ST",
  },
  {
    country_id: 184,
    name: "Saudi Arabia",
    phonecode: "+966",
    iso: "SA",
  },
  {
    country_id: 185,
    name: "Senegal",
    phonecode: "+221",
    iso: "SN",
  },
  {
    country_id: 243,
    name: "Serbia",
    phonecode: "+381",
    iso: "RS",
  },
  {
    country_id: 186,
    name: "Seychelles",
    phonecode: "+248",
    iso: "SC",
  },
  {
    country_id: 187,
    name: "Sierra Leone",
    phonecode: "+232",
    iso: "SL",
  },
  {
    country_id: 188,
    name: "Singapore",
    phonecode: "+65",
    iso: "SG",
  },
  {
    country_id: 189,
    name: "Slovak Republic",
    phonecode: "+421",
    iso: "SK",
  },
  {
    country_id: 190,
    name: "Slovenia",
    phonecode: "+386",
    iso: "SI",
  },
  {
    country_id: 191,
    name: "Solomon Islands",
    phonecode: "+677",
    iso: "SB",
  },
  {
    country_id: 192,
    name: "Somalia",
    phonecode: "+252",
    iso: "SO",
  },
  {
    country_id: 193,
    name: "South Africa",
    phonecode: "+27",
    iso: "ZA",
  },
  {
    country_id: 195,
    name: "Spain",
    phonecode: "+34",
    iso: "ES",
  },
  {
    country_id: 196,
    name: "Sri Lanka",
    phonecode: "+94",
    iso: "LK",
  },
  {
    country_id: 199,
    name: "Sudan",
    phonecode: "+249",
    iso: "SD",
  },
  {
    country_id: 200,
    name: "Suriname",
    phonecode: "+597",
    iso: "SR",
  },
  {
    country_id: 201,
    name: "Svalbard and Jan Mayen Islands",
    phonecode: "+47",
    iso: "SJ",
  },
  {
    country_id: 202,
    name: "Swaziland",
    phonecode: "+268",
    iso: "SZ",
  },
  {
    country_id: 203,
    name: "Sweden",
    phonecode: "+46",
    iso: "SE",
  },
  {
    country_id: 204,
    name: "Switzerland",
    phonecode: "+41",
    iso: "CH",
  },
  {
    country_id: 205,
    name: "Syrian Arab Republic",
    phonecode: "+963",
    iso: "SY",
  },
  {
    country_id: 206,
    name: "Taiwan",
    phonecode: "+886",
    iso: "TW",
  },
  {
    country_id: 207,
    name: "Tajikistan",
    phonecode: "+992",
    iso: "TJ",
  },
  {
    country_id: 208,
    name: "Tanzania, United Republic of",
    phonecode: "+255",
    iso: "TZ",
  },
  {
    country_id: 209,
    name: "Thailand",
    phonecode: "+66",
    iso: "TH",
  },
  {
    country_id: 210,
    name: "Togo",
    phonecode: "+228",
    iso: "TG",
  },
  {
    country_id: 211,
    name: "Tokelau",
    phonecode: "+690",
    iso: "TK",
  },
  {
    country_id: 212,
    name: "Tonga",
    phonecode: "+676",
    iso: "TO",
  },
  {
    country_id: 213,
    name: "Trinidad and Tobago",
    phonecode: "+1868",
    iso: "TT",
  },
  {
    country_id: 214,
    name: "Tunisia",
    phonecode: "+216",
    iso: "TN",
  },
  {
    country_id: 215,
    name: "Turkey",
    phonecode: "+90",
    iso: "TR",
  },
  {
    country_id: 216,
    name: "Turkmenistan",
    phonecode: "+993",
    iso: "TM",
  },
  {
    country_id: 217,
    name: "Turks and Caicos Islands",
    phonecode: "+1649",
    iso: "TC",
  },
  {
    country_id: 218,
    name: "Tuvalu",
    phonecode: "+688",
    iso: "TV",
  },
  {
    country_id: 219,
    name: "Uganda",
    phonecode: "+256",
    iso: "UG",
  },
  {
    country_id: 220,
    name: "Ukraine",
    phonecode: "+380",
    iso: "UA",
  },
  {
    country_id: 221,
    name: "United Arab Emirates",
    phonecode: "+971",
    iso: "AE",
  },
  {
    country_id: 222,
    name: "United Kingdom",
    phonecode: "+44",
    iso: "GB",
  },
  {
    country_id: 223,
    name: "United States",
    phonecode: "+1",
    iso: "US",
  },
  {
    country_id: 225,
    name: "Uruguay",
    phonecode: "+598",
    iso: "UY",
  },
  {
    country_id: 226,
    name: "Uzbekistan",
    phonecode: "+998",
    iso: "UZ",
  },
  {
    country_id: 227,
    name: "Vanuatu",
    phonecode: "+678",
    iso: "VU",
  },
  {
    country_id: 228,
    name: "Vatican City State (Holy See)",
    phonecode: "+39",
    iso: "VA",
  },
  {
    country_id: 229,
    name: "Venezuela",
    phonecode: "+58",
    iso: "VE",
  },
  {
    country_id: 230,
    name: "Viet Nam",
    phonecode: "+84",
    iso: "VN",
  },
  {
    country_id: 231,
    name: "Virgin Islands (British)",
    phonecode: "+1284",
    iso: "VG",
  },
  {
    country_id: 232,
    name: "Virgin Islands (U.S.)",
    phonecode: "+1340",
    iso: "VI",
  },
  {
    country_id: 233,
    name: "Wallis and Futuna Islands",
    phonecode: "+681",
    iso: "WF",
  },
  {
    country_id: 234,
    name: "Western Sahara",
    phonecode: "+212",
    iso: "EH",
  },
  {
    country_id: 235,
    name: "Yemen",
    phonecode: "+967",
    iso: "YE",
  },
  {
    country_id: 238,
    name: "Zambia",
    phonecode: "+260",
    iso: "ZM",
  },
  {
    country_id: 239,
    name: "Zimbabwe",
    phonecode: "+263",
    iso: "ZW",
  },
];
