import {
  TouchInput,
  CardsFilled,
  DiscountTag,
} from "../../../../../commons/components/Icons";
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import "./modal-style.scss";

interface Props {
  landingSettings: any;
}

const HowToRedeemBtn: React.FC<Props> = ({ landingSettings }) => {
  const [openModal, toggleModal] = useState(false);
  const stepData = [
    {
      icon: <TouchInput />,
      title: "Step 1",
      subTitle: `Click on the button '${landingSettings.buttonText}'.`,
    },
    {
      icon: <CardsFilled />,
      title: "Step 2",
      subTitle: "Explore the brand gift cards listed.",
    },
    {
      icon: <TouchInput />,
      title: "Step 3",
      subTitle:
        "Select the desired brand gift card and click on ‘collect voucher’ to redeem it.",
    },
    {
      icon: <DiscountTag />,
      title: "Step 4",
      subTitle:
        "Your voucher code will be revealed and you will also get a mail with all the details.",
    },
  ];
  const renderStep = (
    icon: any,
    title: string,
    subTitle: string,
    index: number
  ) => {
    return (
      <div className="d-flex">
        <div className="mr-1">
          <div className="iconBackground">{icon}</div>
          {index !== 3 ? <div className="vertical" /> : null}
        </div>
        <div className="ml-2">
          <div className="title">{title}</div>
          <div className="subTitle">{subTitle}</div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        className={`howToLink mt-3 ${
          !landingSettings.howToRedeem ? "d-none" : ""
        }`}
        onClick={() => toggleModal(!openModal)}
      >
        How to redeem?
      </div>
      {openModal ? (
        <Modal
          isOpen={openModal}
          toggle={() => toggleModal(!openModal)}
          className="howToRedeemInfo"
        >
          <ModalHeader
            className="modalTitle align-items-center border-bottom-0"
            toggle={() => toggleModal(!openModal)}
            charCode={
              <img src="/img/icons/modalCloseIcon.svg" alt="modalCloseIcon" />
            }
          >
            How to redeem a Xoxo Link
          </ModalHeader>
          <ModalBody className="modalBody">
            <div>
              <div className="redemptionSteps">
                <div className="step">
                  <Row className="flex-column list">
                    {stepData.map((item, idx) => (
                      <Col>
                        {renderStep(item.icon, item.title, item.subTitle, idx)}
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
              <hr className="my-4" />
              <div className="notesSection">
                <div className="label mb-1">NOTE:</div>
                <div>
                  All brand gift cards listed will have the same value. Once you
                  collect a voucher, you cannot change the gift card. You can
                  explore all brands and read the details as long as you do not
                  click on collect voucher.
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export default HowToRedeemBtn;
