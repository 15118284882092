import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Tooltip,
} from "reactstrap";
import DOMPurify from "dompurify";

import { phone_codes as PhoneCodes } from "../../../../utils/locales";
import ApiService from "../../../../api/api.service";
import Select from "react-select";
import ButtonLoader from "../../../../commons/components/Loader/ButtonLoader";
import { Turnstile } from "@plum-xoxo/turnstile_captcha";
import config from "../../../../../config/config.production.json";
import Confirmation from "./Confirmation";

const TURNSTILE_SITE_KEY = config.turnstileSiteKey;

interface Props {
  countryList: any;
  order?: any;
  updateState: Function;
  activeSection: number;
  cityList: any;
  indexSectionMap?: any;
  fetchCitiesList: Function;
  selectedCountry: any;
  setDisableStickyFooter: Function;
  setCityList: Function;
  setDeliveryDetails: Function;
  handleSendOtp: any;
  showCts: any;
  renderTurnstileCaptcha: any;
  toggleTurnstileCaptcha: any;
  loading: Boolean;
  customStyles: any;
  setShowDeliveryAddress: any;
  deliveryDetails: any;
  toggleTnc: any;
}

type errorState = {
  [key: string]: any;
};

const DEFAULT_ERROR_STATE: errorState = {
  shipping_firstname: {
    errMsg: "Please Enter Name",
    showErr: 0,
  },
  shipping_contact_no: {
    errMsg: "Please Enter Valid Phone Number",
    showErr: 0,
  },
  phoneCode: {
    errMsg: "Please Select Dialing Code",
    showErr: 0,
  },
  shipping_address_1: {
    errMsg: {
      noData: "Please Enter Street Address",
      maxCharLimit: "Address cannot be more than 45 characters.",
    },
    showErr: 0,
    showMaxCharErr: 0,
  },
  shipping_zone: {
    errMsg: "Please Enter State",
    showErr: 0,
  },
  shipping_country: {
    errMsg: "Please Select Country",
    showErr: 0,
  },
  shipping_city: {
    errMsg: "Please Enter City",
    showErr: 0,
  },
  shipping_postcode: {
    errMsg: "Please Enter Valid ZIP/PIN Code",
    showErr: 0,
  },
};

const DeliveryAddress: React.FC<Props> = ({
  countryList,
  updateState,
  activeSection,
  cityList,
  fetchCitiesList,
  selectedCountry,
  setDisableStickyFooter,
  setCityList,
  setDeliveryDetails,
  handleSendOtp,
  showCts,
  renderTurnstileCaptcha,
  toggleTurnstileCaptcha,
  loading,
  setShowDeliveryAddress,
  deliveryDetails,
  toggleTnc,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [username, setUsername] = useState("");
  const [shipping_firstname, setFirstName] = useState("");
  const [shipping_lastname, setLastName] = useState("");
  const [shipping_contact_no, setContactNo] = useState("");
  const [shipping_address_1, setAddress1] = useState("");
  const [shipping_address_2, setAddress2] = useState("");
  const [shipping_city, setCity] = useState("");
  const [shipping_postcode, setPostcode] = useState("");
  const [shipping_zone, setZone] = useState("");
  const [shipping_landmark, setLandmark] = useState("");
  const [shipping_country, setCountry] = useState({
    countryCode: null,
    countryId: null,
    label: null,
    value: null,
  });
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [errorState, setErrorState] = useState(DEFAULT_ERROR_STATE);
  const [PhoneCodeOptions, setPhoneCodes] = useState([]);
  const [phoneCode, setPhoneCode] = useState<any>({});
  const [pincodeLoader, setPincodeLoader] = useState<boolean>(false);
  const delivery_details: errorState = {
    shipping_firstname,
    shipping_lastname,
    shipping_contact_no,
    shipping_address_1,
    shipping_address_2,
    shipping_country,
    shipping_city,
    shipping_postcode,
    shipping_zone,
    phoneCode,
  };

  const inputRef: any = useRef(null);

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const toggle = () =>
    setIsTooltipOpen((prev) => {
      return !prev;
    });

  useEffect(() => {
    if (countryList.length === 1) {
      const preFillObj = {
        countryCode: countryList[0].countryCode,
        countryId: countryList[0].countryId,
        label: countryList[0].label,
        value: countryList[0].value,
      };
      setCountry(preFillObj);
    }
  }, [countryList]);

  useEffect(() => {
    const localPhoneCodeOptions: any =
      PhoneCodes &&
      PhoneCodes.map((item: any, index: number) => {
        const obj = item;
        obj.label = `${item.iso} ${item.phonecode}`;
        obj.value = item.country_id;
        return obj;
      });
    setPhoneCodes(localPhoneCodeOptions);
    if (!Object.keys(phoneCode || {}).length) {
      let tempPhoneCode: any = "";
      tempPhoneCode = PhoneCodes.filter(
        (item) => item && item.iso === selectedCountry.countryCode
      )[0];
      setPhoneCode(tempPhoneCode);
    }
  }, [selectedCountry]);
  const toggleOrderSummaryDisable = (disable = false) => {
    //if (disable && isMobile()) {
    //  updateState({ disableStickyFooter: true });
    //} else {
    setDisableStickyFooter(false);
    //}
  };
  const handleName = (event: any) => {
    const regex = /^[a-zA-Z][a-zA-Z]*\s?[a-zA-Z]*$/;
    if (!regex.test(event.target.value) && event.target.value != "") {
      return;
    }
    const name: string = event.target.value.trimLeft().replace(/  +/g, " ");
    const value: any = name.split(" ");
    if (value.length) {
      setUsername(name);
      setFirstName(value[0]);
      setLastName((value.slice(1, value.length).join(" ") || "").trimLeft());
    }
  };
  const updateAddress1 = (event: any) => {
    const tempErrorState = { ...errorState };
    if (event.target.value.length <= 45) {
      setAddress1(event.target.value.trimStart());
      tempErrorState.shipping_address_1.showMaxCharErr = 0;
    } else {
      const tempErrorState = { ...errorState };
      tempErrorState.shipping_address_1.showMaxCharErr = 1;
    }
    setErrorState(tempErrorState);
  };
  const updateAddress2 = (event: any) => {
    if (event.target.value.length <= 45) {
      setAddress2(event.target.value.trimStart());
    }
  };
  const handleLandmark = (event: any) => {
    setLandmark(event.target.value);
  };
  const handleCityUpdate = (event: any) => {
    setCity(event.target.value);
  };
  const validatePinCode = async () => {
    const tempErrorState = { ...errorState };

    if (shipping_postcode.length > 9 || shipping_postcode.length === 0) {
      tempErrorState.shipping_postcode.showErr = 1;
      return;
    } else {
      tempErrorState.shipping_postcode.showErr = 0;
    }
    setPincodeLoader(true);
    const response = await ApiService.fetchDataFromPincode(
      shipping_postcode,
      shipping_country.countryCode || "IN"
    );

    if (response.success && response.city && response.state) {
      setCity(response.city);
      setZone(response.state);
      let tempErrors = { ...errorState };
      tempErrors.shipping_city.showErr = 0;
      tempErrors.shipping_zone.showErr = 0;
      setErrorState(tempErrors);
    }
    setPincodeLoader(false);
  };

  const handlePincode = (value: string) => {
    errorState.shipping_postcode.showErr = 0;
    if (value.length > 9) {
      return;
    }
    const formattedValue = value.replace(/ /g, "");
    setPostcode(formattedValue);
  };

  const handlePhoneNumber = (event: any) => {
    const reg: any = /^\d+$/;
    if (
      !(event.target.value.length === 0 && event.target.value === "") &&
      !(reg.test(event.target.value) && parseInt(event.target.value) !== 0)
    ) {
      return;
    }
    if (event.target.value.length > 16) {
      return;
    }
    const trimmedContact = event.target.value.replace(/^0+/, "");
    setContactNo(trimmedContact);
  };
  const handlePhoneCodes = (event: any) => {
    setPhoneCode(event);
  };
  const handleCountrySelect = (event: any) => {
    setCityList([]);
    setCountry(event);
    setZone("");
    setCity("");
  };
  const handleStateSelect = (event: any) => {
    //setCityList([]);
    setZone(event.target.value);
    // setCity("");
    // fetchCitiesList(event.stateId);
  };
  const validateDeliveryDetails = () => {
    let isFieldsError = false;
    const tempErrorState = { ...errorState };
    Object.keys(DEFAULT_ERROR_STATE).forEach((item: any, ab: any) => {
      if (
        !delivery_details[item] ||
        (typeof delivery_details[item] === "string" &&
          !delivery_details[item].trim())
      ) {
        tempErrorState[item].showErr = 1;
        isFieldsError = true;
      } else {
        tempErrorState[item].showErr = 0;
      }
    });
    if (shipping_contact_no.length > 16 || shipping_contact_no.length < 7) {
      tempErrorState.shipping_contact_no.showErr = 1;
      isFieldsError = true;
    }
    if (isFieldsError) {
      setErrorState(tempErrorState);
      const element = document.getElementById("deliveryInfoForm");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      const parsed_shipping_add2 = DOMPurify.sanitize(shipping_address_2, {
        ALLOWED_TAGS: [],
      });
      const parsed_shipping_landmark = DOMPurify.sanitize(shipping_landmark, {
        ALLOWED_TAGS: [],
      });
      const deliveryDetails = {
        firstname: DOMPurify.sanitize(shipping_firstname, {
          ALLOWED_TAGS: [],
        }),
        lastname: DOMPurify.sanitize(shipping_lastname, {
          ALLOWED_TAGS: [],
        }),
        phoneCode,
        contact_no: shipping_contact_no,
        address_1: DOMPurify.sanitize(shipping_address_1, {
          ALLOWED_TAGS: [],
        }),
        address_2: parsed_shipping_landmark.length
          ? `${parsed_shipping_add2}, ${parsed_shipping_landmark}`
          : parsed_shipping_add2,
        zone: shipping_zone,
        city: shipping_city,
        postcode: shipping_postcode,
        country: shipping_country.value || "India",
      };
      setDeliveryDetails(deliveryDetails);
      setShowConfirmation(true);
    }
  };
  const triggerOtp = () => {
    showCts ? toggleTurnstileCaptcha(true) : handleSendOtp({});
  };
  const renderDeliveryInfoDetails = () => (
    <>
      <div className="d-flex align-items-center mt-2">
        <Label textType="body" size="S">
          * Your order will be delivered by our fulfillment partner with in{" "}
          <span>3-7 days</span>
        </Label>
      </div>
      <Label className="mt-2" textType="body" size="S" isItalic>
        On the day of delivery, you may receive validation code on your contact
        details from our delivery partners.
      </Label>
    </>
  );
  return (
    <>
      {showConfirmation ? (
        <Confirmation
          setShowConfirmation={setShowConfirmation}
          deliveryDetails={deliveryDetails}
          status="CONFIRMATION"
          toggleTnc={toggleTnc}
        />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ marginTop: "-30px" }}
            >
              <Row className="d-flex pb-2 pl-3">
                <div>
                  <a
                    onClick={() => {
                      setShowDeliveryAddress(false);
                      setErrorState({});
                      setFirstName("");
                      setLastName("");
                      setAddress1("");
                      setAddress2("");
                      setLandmark("");
                      setCity("");
                      setPostcode("");
                    }}
                  >
                    <img src="/img/icons/back-arrow.svg" alt="backBtnIcon" />
                  </a>
                </div>
                <div className="w-75 ml-3">
                  <>
                    <Row>
                      <Col className="h6 mb-0">Enter your delivery details</Col>
                    </Row>
                    <Row>
                      <Col className="text-color-ink-light">
                        Provide us the delivery details where this gift will be
                        delivered.
                      </Col>
                    </Row>
                  </>
                </div>
              </Row>
            </div>
          </div>

          <div className="title-divider" />
          <>
            <Row className="deliveryInfoForm" id="deliveryInfoForm">
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label
                  className="text-black m-0 mb-1 inkLight"
                  textType="body"
                  size="S"
                >
                  Name
                </Label>
                <Input
                  onChange={(e: any) => handleName(e)}
                  placeholder="Enter your Full name"
                  invalid={errorState.shipping_firstname.showErr}
                  value={username}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                  onBlur={() => {
                    toggleOrderSummaryDisable();
                  }}
                  innerRef={inputRef}
                />
                {errorState.shipping_firstname.showErr ? (
                  <FormFeedback>
                    {errorState.shipping_firstname.errMsg}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  Phone Number
                </Label>
                <Row className="phoneNumberInput">
                  <Col xs={4} className="dialingCode pr-0">
                    <Select
                      options={PhoneCodeOptions}
                      value={phoneCode}
                      invalid={errorState.phoneCode.showErr}
                      helperText={
                        errorState.phoneCode.showErr
                          ? errorState.phoneCode.errMsg
                          : null
                      }
                      placeholder="Dialing Code"
                      onChange={(e: any) => {
                        handlePhoneCodes(e);
                      }}
                      onBlur={() => {
                        toggleOrderSummaryDisable();
                      }}
                      onFocus={() => toggleOrderSummaryDisable(true)}
                      className="react-select"
                      styles={{
                        control: (base: any, state: any) => ({
                          ...base,
                          height: "42px",
                          minHeight: "42px",
                          borderRadius: "2px 0 0 2px",
                          borderColor: state.isFocused ? "#c7417b" : "#d6d6d6",
                        }),
                      }}
                    />
                  </Col>
                  <Col xs={8} className="pl-0">
                    <Input
                      value={shipping_contact_no}
                      onChange={(e: any) => {
                        handlePhoneNumber(e);
                      }}
                      placeholder="Enter your phone number"
                      onBlur={() => {
                        toggleOrderSummaryDisable();
                      }}
                      invalid={errorState.shipping_contact_no.showErr}
                      helperText={
                        errorState.shipping_contact_no.showErr
                          ? errorState.shipping_contact_no.errMsg
                          : null
                      }
                      onFocus={() => toggleOrderSummaryDisable(true)}
                      style={{
                        borderRadius: "0 2px 2px 0",
                      }}
                    />
                    {errorState.shipping_contact_no.showErr ? (
                      <FormFeedback>
                        {errorState.shipping_contact_no.errMsg}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  Address Line 1
                </Label>
                <Input
                  onChange={(e: any) => updateAddress1(e)}
                  placeholder="Enter Street Address"
                  invalid={
                    errorState.shipping_address_1.showErr ||
                    errorState.shipping_address_1.showMaxCharErr
                  }
                  helperText={
                    errorState.shipping_address_1.showErr
                      ? errorState.shipping_address_1.errMsg.noData
                      : errorState.shipping_address_1.showMaxCharErr
                      ? errorState.shipping_address_1.errMsg.maxCharLimit
                      : null
                  }
                  value={shipping_address_1}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                  onBlur={(e: any) => {
                    toggleOrderSummaryDisable();
                    updateAddress1(e);
                  }}
                  limit={45}
                />
                {errorState.shipping_address_1.showErr ? (
                  <FormFeedback>
                    {errorState.shipping_address_1.showErr
                      ? errorState.shipping_address_1.errMsg.noData
                      : errorState.shipping_address_1.showMaxCharErr
                      ? errorState.shipping_address_1.errMsg.maxCharLimit
                      : null}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  Address Line 2
                </Label>
                <Input
                  onChange={(e: any) => updateAddress2(e)}
                  placeholder="Enter Additional Address"
                  value={shipping_address_2}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                  onBlur={(e: any) => {
                    updateAddress2(e);
                    toggleOrderSummaryDisable();
                  }}
                  limit={45}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  Country
                </Label>
                <Select
                  options={countryList}
                  placeholder="Choose your Country"
                  isDisabled={countryList.length <= 1}
                  value={
                    Object.keys(shipping_country).length
                      ? {
                          label: shipping_country.label,
                          value: shipping_country.value,
                        }
                      : {}
                  }
                  invalid={errorState.shipping_country.showErr}
                  helperText={
                    errorState.shipping_country.showErr
                      ? errorState.shipping_country.errMsg
                      : null
                  }
                  onChange={(e: any) => {
                    handleCountrySelect(e);
                  }}
                  onBlur={() => {
                    toggleOrderSummaryDisable();
                  }}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                  className="react-select mr-2"
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      height: "42px",
                      minHeight: "42px",
                    }),
                  }}
                />
                {errorState.shipping_country.showErr ? (
                  <FormFeedback>
                    {errorState.shipping_country.errMsg}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label
                  className="mb-1 d-flex align-items-center text-black m-0"
                  textType="body"
                  size="S"
                >
                  ZIP/PIN Code <span id="postalcode_info"></span>
                </Label>
                <Tooltip
                  target="postalcode_info"
                  placement="top"
                  toggle={toggle}
                  isOpen={isTooltipOpen}
                >
                  <Label variant="label4">
                    Entering the correct ZIP/PIN Code allows us to deliver your
                    order accurately.
                  </Label>
                </Tooltip>
                <Input
                  endIcon={pincodeLoader ? <ButtonLoader /> : null}
                  disabled={!shipping_country.value}
                  onChange={(e: any) =>
                    handlePincode(e.target.value.toUpperCase().trimLeft())
                  }
                  placeholder="Enter your ZIP/PIN Code"
                  invalid={errorState.shipping_postcode.showErr}
                  helperText={
                    errorState.shipping_postcode.showErr
                      ? errorState.shipping_postcode.errMsg
                      : null
                  }
                  maxLength={9}
                  value={shipping_postcode}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                  onBlur={() => {
                    validatePinCode();
                    toggleOrderSummaryDisable();
                  }}
                />
                {errorState.shipping_postcode.showErr ? (
                  <FormFeedback>
                    {errorState.shipping_postcode.errMsg}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  State
                </Label>
                <Input
                  disabled={pincodeLoader}
                  placeholder="Enter your State"
                  invalid={errorState.shipping_city.showErr}
                  helperText={
                    errorState.shipping_zone.showErr
                      ? errorState.shipping_zone.errMsg
                      : null
                  }
                  value={shipping_zone}
                  onChange={(e: any) => {
                    handleStateSelect(e);
                  }}
                  onBlur={() => {
                    toggleOrderSummaryDisable();
                  }}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  City
                </Label>
                <Input
                  disabled={pincodeLoader}
                  placeholder="Enter your City/Town"
                  invalid={errorState.shipping_city.showErr}
                  helperText={
                    errorState.shipping_city.showErr
                      ? errorState.shipping_city.errMsg
                      : null
                  }
                  value={shipping_city}
                  onChange={(e: any) => {
                    handleCityUpdate(e);
                  }}
                  onBlur={() => {
                    toggleOrderSummaryDisable();
                  }}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                />
              </Col>
              {errorState.shipping_postcode.showErr ? (
                <FormFeedback>
                  {errorState.shipping_postcode.errMsg}
                </FormFeedback>
              ) : null}
              <Col xs={12} sm={12} md={6} lg={6} className="mb-2">
                <Label textType="body" size="S" className="text-black m-0 mb-1">
                  Landmark (optional)
                </Label>
                <Input
                  onChange={(e: any) => handleLandmark(e)}
                  placeholder="Enter a Nearest Landmark"
                  value={shipping_landmark}
                  onFocus={() => toggleOrderSummaryDisable(true)}
                  onBlur={() => {
                    toggleOrderSummaryDisable();
                  }}
                  limit={45}
                />
              </Col>
            </Row>
            <div className="my-3 py-1">{renderDeliveryInfoDetails()}</div>
            <div className="title-divider my-3" />
          </>
        </>
      )}
      <div className={`d-flex justify-content-center`}>
        <>
          {renderTurnstileCaptcha ? (
            <Turnstile
              className="text-center"
              siteKey={TURNSTILE_SITE_KEY}
              onSuccess={(token: any) => handleSendOtp({ token })}
              options={{ theme: "light" }}
              style={{ width: "100%" }}
            />
          ) : showConfirmation ? (
            <button className="btn btn-filled mt-2" onClick={triggerOtp}>
              Send OTP
            </button>
          ) : (
            <button
              className="btn btn-filled mt-2"
              disabled={pincodeLoader || loading ? true : false}
              onClick={() => validateDeliveryDetails()}
            >
              {loading || pincodeLoader ? (
                <ButtonLoader />
              ) : (
                "Save and Deliver Here"
              )}
            </button>
          )}
        </>
      </div>
      <Col className=" tnc-link my-3">
        <div onClick={toggleTnc} className="cursor-pointer">
          Terms & conditions
        </div>{" "}
        |{" "}
        <div onClick={toggleTnc} className="cursor-pointer">
          How to use
        </div>
      </Col>
    </>
  );
};

export default DeliveryAddress;
