import React from "react";
import DescriptionText from "../components/DescriptionText";
import HowToRedeemBtn from "../components/HowToRedeemBtn";
import RedeemBtn from "../components/RedeemBtn";
import "./style.scss";

interface LayoutProps {
  landingSettings: any;
  showVouchers: Function;
}

const SimpleLayout: React.FC<LayoutProps> = ({
  landingSettings,
  showVouchers,
}) => {
  const isMobileDevice = window.screen.width < 992;
  return (
    <div className="h-100 simpleLayout">
      <div
        className="h-100 backGroundImg"
        style={{
          background: `${
            landingSettings.imageStatus
              ? `url(${landingSettings.image}) no-repeat center top`
              : "#fff"
          }`,
        }}
      >
        <div
          className={`text-center previewBody position-relative ${
            isMobileDevice ? "mobile" : ""
          }`}
        >
          {landingSettings.logoStatus ? (
            <div className={`logoHeader ${isMobileDevice ? "mobile" : ""}`}>
              <img src={landingSettings.logo} />
            </div>
          ) : null}
          <div
            className="content"
            style={{
              overflowY: "auto",
              height: landingSettings.logoStatus
                ? !isMobileDevice
                  ? "calc(100% - 89px)"
                  : "calc(100% - 50px)"
                : "100%",
            }}
          >
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div
                className={`layoutTitle font-weight-bold ${
                  isMobileDevice ? "mobile" : ""
                } ${!landingSettings.titleStatus ? "d-none " : ""}`}
                style={{
                  color: landingSettings.titleColour,
                }}
              >
                {landingSettings.title}
              </div>
              <DescriptionText
                description={landingSettings.description}
                customClass={isMobileDevice ? "mobile" : ""}
                descriptionColour={landingSettings.descriptionColour}
                descriptionStatus={landingSettings.descriptionStatus}
              />
              <RedeemBtn
                buttonText={landingSettings.buttonText}
                customClass={isMobileDevice ? "mobile" : ""}
                buttonColour={landingSettings.buttonColour}
                buttonTextColour={landingSettings.buttonTextColour}
                showVouchers={() => showVouchers()}
              />
              <HowToRedeemBtn landingSettings={landingSettings} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleLayout;
