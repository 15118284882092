export const ROUTE_PATHS = {
  HOME: "/",
  COMMON: "/common",
  PRODUCT_LIST: "/product-list",
  EXPERIENCE_DESCRIPTION: "/experiences/:description",
  EXPERIENCE_DESCRIPTION_PRODUCT: "/experiences/:description/:productID",
  EXPERIENCES_LST: "/experiences",
  VOUCHERS_LIST: "/vouchers",
  PERKS_LIST: "/perks",
  PROFILE: "/profile",
  CHECKOUT: "/checkout",
  YOUR_ORDERS: "/orders",
  XOXO_POINTS: "/points",
  XOXO_VOUCHERS: "/xoxovouchers",
  VOUCHER_DESCRIPTION: "/vouchers/description",
  SEARCH: "/search",
  PAYMENTCALLBACK: "/payment-callback",
  PAYMENTEXPCALLBACK: "/payment-exp-callback",
  PAYMENTSTATUS: "/payment-status",
  CLAIMREWARD: "/claim-reward",
};

export const PLUM_ROUTES = [
  "common",
  "product-list",
  "experiences",
  "vouchers",
  "perks",
  "profile",
  "checkout",
  "orders",
  "points",
  "xoxovouchers",
  "search",
  "payment-callback",
  "payment-exp-callback",
  "payment-status",
  "reset-password",
  "admin",
  "reset-password",
  "claim-reward",
];

export const DEFAULT_PREFIX = {
  DEFAULT: "marketplace",
};
