import React, { useState, useEffect, memo, useRef } from "react";
import TncBack from "../home/components/Cards/Tnc";
import ButtonLoader from "../../commons/components/Loader/ButtonLoader";
import { Modal, ModalBody, ModalHeader, Row, Col, Input } from "reactstrap";
import Select, { components } from "react-select";
import OtpInput from "react-otp-input";
import ApiService from "../../api/api.service";
import {
  checkGivenEmailIsValid,
  checkGivenMobileNumberIsValid,
  makeRequestAuth,
} from "../../utils/globals";
interface PostParams {
  email: string;
  otp_id?: string;
  otp?: string;
  automation_id: String;
  responseID: String;
  app_code: String;
}

export default memo(function OtpValidationModal({
  removeSelected,
  selected,
  otp_id,
  email,
  automation_id,
  responseID,
  app_code,
  onRewardSent,
  onResend,
}: any) {
  //const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableCollectVoucherBtn, setdisableCollectVoucherBtn] =
    useState(true);
  const [showTnc, setShowTnc] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedUserInfo, setSelectedUserInfo] = useState<any>("email");

  const [otpId, setOtpId] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpValue, setOtpValue] = useState("");

  const [seconds, setSeconds] = useState(60);
  let intervalRef = useRef<any>();

  const decreaseNum = () => setSeconds((prev) => prev - 1);
  const otpInputRef = useRef<any>();

  useEffect(() => {
    if (otp_id) {
      intervalRef.current = setInterval(decreaseNum, 1000);
      return () => clearInterval(intervalRef.current);
    }
  }, [otpId]);

  useEffect(() => {
    setOtpError("");
    if (otpValue.length === 6) {
      disableCollectVoucherBtn && setdisableCollectVoucherBtn(false);
    } else {
      !disableCollectVoucherBtn && setdisableCollectVoucherBtn(true);
    }
  }, [otpValue]);

  const handleEmailChange = (event: any) => {
    let email = event.target.value;
    // setEmail(email);
  };
  const toggleTnc = () => {
    setShowTnc((prevState) => {
      return !prevState;
    });
  };
  const handleValidateInput = (field: string = "both") => {
    if (!email) {
      setDisableBtn(true);
      return false;
    }
    let validEmail = isEmailValid;

    if ((field === "email" || field === "both") && email.length > 0) {
      validEmail = checkGivenEmailIsValid(email);
    }

    setIsEmailValid(validEmail);

    if (field === "email" && !validEmail) {
      setDisableBtn(true);
      return false;
    } else {
      setDisableBtn(false);
      return true;
    }
  };

  const handleSendOtp = async (resend = false) => {
    setOtpError("");
    setOtpValue("");
    setLoading(true);
    setSeconds(60);
    let payload = {
      email_id: email,
      automation_id: automation_id,
      responseID: responseID,
      otp_id: otp_id,
    };

    let urlObject = `/integration/anonymous/${app_code}/resendotp`;
    const data = ApiService.claimReward(payload, urlObject);
    data.then((data: any) => {
      setLoading(false);
      if (data && data.data && data.data.success == true) {
        onResend(data.data.otp_id);
      } else {
        setOtpError(
          data.data.message ? data.data.message : "Something went wrong!"
        );
        setLoading(false);
      }
    });
  };

  const claimReward = async () => {
    setLoading(true);
    let payload = {
      email_id: email,
      automation_id: automation_id,
      responseID: responseID,
      otp_id: otp_id,
      otp: otpValue,
    };

    let urlObject = `/integration/anonymous/${app_code}/validateotp`;
    const data = ApiService.claimReward(payload, urlObject);
    data.then((data: any) => {
      if (data && data.data && data.data.success == true) {
        setLoading(false);
        onRewardSent();
      } else {
        setOtpError(
          data.data.message ? data.data.message : "Something went wrong!"
        );
        setLoading(false);
      }

      //this.setState({ loading: false })
    });
  };

  // const onChangeUserDetailsSelect = (e: any) => {
  //   setSelectedUserInfo(e.target.value);
  //   setEmail("");

  // };

  // const tncAndHowToUse = (
  //   <Col className=" tnc-link">
  //     <span onClick={toggleTnc}>Terms & conditions</span> |{" "}
  //     <span onClick={toggleTnc}>How to use</span>
  //   </Col>
  // );

  const renderOtp = () => (
    <>
      <Row className="mb-4">
        <Col lg="1">
          {" "}
          <a
            onClick={() => {
              setOtpId("");
              setOtpValue("");
              setOtpError("");
              setSeconds(60);
            }}
          >
            <img src="/img/icons/back-arrow.svg" alt="backBtnIcon" />
          </a>
        </Col>
        <Col lg="11">
          <>
            <Row>
              <Col className="h6">Verify your email address</Col>
            </Row>
            <Row>
              <Col className="text-color-ink-light">
                Enter the OTP we just sent to the email address {email}
              </Col>
            </Row>
          </>
        </Col>
      </Row>
      <Row className="text-center mt-4">
        <Col className="column-center">
          <OtpInput
            ref={otpInputRef}
            shouldAutoFocus={true}
            value={otpValue}
            onChange={setOtpValue}
            numInputs={6}
            separator={
              <span>
                <strong> </strong>
              </span>
            }
            inputStyle={{
              width: "3rem",
              height: "3rem",
              margin: "0 0.2rem",
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "16px",
              borderRadius: "4px",
              color: "#000",
              border: "1px solid #BAC7D5",
            }}
            className="column-center"
          />
        </Col>
      </Row>
      <Row className="text-center mt-2">
        <Col className="text-error">
          {otpError ? "OTP does not match" : null}
        </Col>
      </Row>
      <Row className="text-center mt-4">
        <Col>
          {seconds > 0 && seconds < 60 ? (
            <div className="text-color-ink-light">
              Resend OTP in{" "}
              <span className="exclude-translation">{seconds}</span> second(s)
            </div>
          ) : (
            <a
              onClick={() => {
                handleSendOtp(true);
              }}
              className="blue-link"
            >
              Resend OTP
            </a>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <button
            className="btn btn-filled mt-2"
            onClick={() => handleSendOtp()}
            disabled={disableCollectVoucherBtn || loading}
          >
            {loading ? <ButtonLoader /> : "Collect Voucher"}
          </button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {!showTnc ? (
        <Modal
          size="lg"
          isOpen={selected > 0}
          className={`voucher-modal ${showTnc ? "flip1" : ""}`}
          toggle={removeSelected}
          fade={false}
          scrollable
        >
          <ModalHeader className="without-border-bottom-otp">
            <div style={{ display: "flex" }}>
              <div className="back-icon mr-2" onClick={removeSelected}>
                <img src="/img/icons/back-arrow.svg" alt="icon" />
              </div>
              <div>
                <h3 className="heading">Verify your email address</h3>
                <p className="subText">
                  Enter the OTP we just sent to the email address {email}
                </p>
              </div>
            </div>
          </ModalHeader>

          <ModalBody>
            <div>
              <Row className="text-center mt-4">
                <Col className="column-center">
                  <OtpInput
                    ref={otpInputRef}
                    shouldAutoFocus={true}
                    value={otpValue}
                    onChange={setOtpValue}
                    numInputs={6}
                    separator={
                      <span>
                        <strong> </strong>
                      </span>
                    }
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "0 0.2rem",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      borderRadius: "4px",
                      color: "#000",
                      border: "1px solid #BAC7D5",
                    }}
                    className="column-center"
                  />
                </Col>
              </Row>
              <Row className="text-center mt-2">
                <Col className="text-error">
                  {otpError ? "OTP does not match" : null}
                </Col>
              </Row>
              <Row className="text-center mt-4">
                <Col>
                  {seconds > 0 && seconds < 60 ? (
                    <div className="text-color-ink-light">
                      Resend OTP in{" "}
                      <span className="exclude-translation">{seconds}</span>{" "}
                      second(s)
                    </div>
                  ) : (
                    <a
                      onClick={() => {
                        handleSendOtp(true);
                      }}
                      className="blue-link"
                    >
                      Resend OTP
                    </a>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <button
                    className="btn claim-btn-filled mt-2"
                    onClick={() => claimReward()}
                    disabled={disableCollectVoucherBtn || loading}
                  >
                    {loading ? <ButtonLoader /> : "Claim Reward"}
                  </button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
});
