import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
// import { CLOUDINARY_URL } from '../../../../commons/constants'
import { stripHtml } from "../../../../utils/helpers";
import { Modal, ModalBody, ModalHeader, ModalFooter, Col } from "reactstrap";

const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;

interface Props {
  showTnc: boolean;
  toggleTnc?: Function;
  data: any;
  overlay?: boolean;
}

const TncBack: React.FC<Props> = (props) => {
  const [showMessage, setShowMessage] = useState(true);
  const { overlay = true } = props;

  const func = () => {
    // setShowMessage(false)
    if (props.toggleTnc) {
      props.toggleTnc();
    }
  };

  const handleHtml = (html: string) => {
    // var temporalDivElement = document.createElement("div");
    // temporalDivElement.innerHTML = html;
    // html = temporalDivElement.textContent || temporalDivElement.innerText || "";
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  return (
    <Modal
      size="sm"
      isOpen={props.showTnc}
      className={`voucher-modal ${!overlay ? "voucher-modal-shadow" : ""} `}
      fade={false}
      toggle={func}
      scrollable
      backdrop={overlay}
    >
      {/* {props.overlay ? <div className="overlay"></div> : null} */}
      <ModalBody>
        <div className="tnc-conten">
          <div>
            <h3 className="inkNormal tnc">How to use</h3>
            {handleHtml(
              props.data && props.data.how_to_use ? props.data.how_to_use : ""
            )}
          </div>
          {showMessage ? (
            <div>
              <h3 className="inkNormal tnc">Terms & Conditions</h3>

              <div>
                {handleHtml(
                  props.data && props.data.terms_conditions
                    ? props.data.terms_conditions
                    : ""
                )}
              </div>
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <Col className="text-center">
          <span className="btn btn-default close-modal-btn" onClick={func}>
            <strong>Ok, got it</strong>
          </span>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default TncBack;
