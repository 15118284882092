import React from "react";

interface Props {
  buttonText: string;
  customClass: string;
  buttonTextColour: string;
  buttonColour: string;
  showVouchers: any;
}

const RedeemBtn: React.FC<Props> = ({
  buttonText,
  customClass,
  buttonTextColour,
  buttonColour,
  showVouchers,
}) => {
  return (
    <div
      className={`mt-4 redeemBtn ${customClass ? customClass : ""}`}
      onClick={() => showVouchers()}
      style={{
        backgroundColor: buttonColour,
        color: buttonTextColour,
      }}
    >
      <span>{buttonText}</span>
    </div>
  );
};

export default RedeemBtn;
